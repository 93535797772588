import { useQuery } from "react-query";
import { AuthorizedService } from "shared/axios";
import { isAdmin } from "utils/global";

export const GET_STATUS_WISE_COUNT_QUERY_KEY = "status-wise-count";
const END_POINT = "/installation";

export interface StatusWiseDataCount {
  allBooking: number;
  newOrder: number;
  openOrder: number;
  completeOrder: number;
  accountingOrder: number;
}

export const useGetStatusWiseCount = () => {
  return useQuery<StatusWiseDataCount>({
    queryKey: [GET_STATUS_WISE_COUNT_QUERY_KEY],
    queryFn: async () => {
      const res = await new AuthorizedService().get(
        `${END_POINT}/status-wise-count`
      );
      return res.data;
    },
    enabled: isAdmin(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
