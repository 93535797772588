import { message } from "antd";
import { ParkedCarFormData } from "components/workerOrders/WorkerParkedCar";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AuthorizedService } from "shared/axios";

const END_POINT = "/parked-car";
const PARKED_CAR_LIST_QUERY_KEY = "parked-car-list";

export type ParkedCar = {
  id: string;
  carColor: string;
  licensePlate: string;
  brand: {
    id: string;
    name: string;
  };
  createdAt: string;
  updatedAt: string;
};

export const useGetParkedCarList = (installationId: string) => {
  return useQuery<ParkedCar[]>({
    queryKey: [PARKED_CAR_LIST_QUERY_KEY, installationId],
    queryFn: async () => {
      const res = await new AuthorizedService().get(
        END_POINT + `/${installationId}`
      );
      return res.data;
    },
  });
};

export const useParkedCarDataUpload = (installationId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ParkedCarFormData) => {
      await new AuthorizedService().post(END_POINT, data);
    },
    onSuccess: () => {
      message.success(t("upload:uploadSuccess"));
    },
    onError: () => {
      message.error(t("upload:uploadFail"));
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        PARKED_CAR_LIST_QUERY_KEY,
        installationId,
      ]);
    },
  });
};

export const useDeleteParkedCar = (installationId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (parkedCarId: string) => {
      await new AuthorizedService().remove(END_POINT + `/${parkedCarId}`);
    },
    onSuccess: () => {
      message.success(t("general:deleteSuccess"));
    },
    onError: () => {
      message.error(t("general:deleteFail"));
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        PARKED_CAR_LIST_QUERY_KEY,
        installationId,
      ]);
    },
  });
};
