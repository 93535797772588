import SideNav from "pages/layout/header/sideNav";
import { Footer, Header } from "pages";
import { useState } from "react";
import { useAuth } from "queryHook/auth";
import { useLocation } from "react-router";

const LayoutWrapper = ({ children }: any) => {
	const [onlyIcons, setOnlyIcons] = useState<boolean>(false);
	const { pathname } = useLocation<any>();
	const { isLoggedIn } = useAuth();
	const firstSegement =
		pathname.split("/")?.length > 1 ? "/" + pathname.split("/")[1] : "/";

	return (
		<>
			{isLoggedIn ? (
				<>
					<Header
						onlyIcons={onlyIcons}
						toggleOnlyIcons={() => setOnlyIcons((prev) => !prev)}
					/>
					<div className="page-wrap">
						<SideNav onlyIcons={onlyIcons} firstSegement={firstSegement} />
						<div className="page-content">{children}</div>
					</div>

					<Footer />
				</>
			) : (
				children
			)}
		</>
	);
};
export default LayoutWrapper;
