import { Image, Select } from "antd";
import { useInstallationWorkerInformationUpdate } from "queryHook/installation";
import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";
import { INSTRUCTION_OPTIONS } from "utils/global/constants";

export default function Instruction({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();

  const { mutate, isLoading } = useInstallationWorkerInformationUpdate(
    installation.id
  );

  const onChange = (value) => {
    mutate({
      workerInstructionNumber: value,
    });
  };

  const { workerInstructionNumber } = installation;

  return (
    <div>
      <h5 className="underline">{t("general:instructions")}:</h5>
      <div className="grid grid-cols-2 gap-4">
        <Select
          options={INSTRUCTION_OPTIONS}
          onChange={onChange}
          value={workerInstructionNumber ?? undefined}
          defaultValue={workerInstructionNumber ?? undefined}
          disabled={isLoading}
        />

        {workerInstructionNumber && (
          <Image
            src={`/img/instruction/${workerInstructionNumber}.jpg`}
            alt={`${t("general:instructions")} ${workerInstructionNumber}`}
            height={200}
            style={{
              objectFit: "contain",
            }}
          />
        )}
      </div>
    </div>
  );
}
