import { useQuery } from "react-query";
import { AuthorizedService } from "shared/axios";
import { isAdmin } from "utils/global";

export const GET_GOOGLE_EVENT_COLORS_QUERY_KEY = "google-event-colors";
const END_POINT = "/google-colors";

export interface StatusWiseDataCount {
  allBooking: number;
  newOrder: number;
  openOrder: number;
  completeOrder: number;
  accountingOrder: number;
}

export const useGetGoogleCalendarEventColors = () => {
  return useQuery<any>({
    queryKey: [GET_GOOGLE_EVENT_COLORS_QUERY_KEY],
    queryFn: async () => {
      const res = await new AuthorizedService().get(`${END_POINT}`);
      return res.data;
    },
    enabled: isAdmin(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
