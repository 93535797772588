export const categoryTypeOption = [
  {
    value: "news",
    label: "News",
  },
  {
    value: "feed",
    label: "Feed",
  },
  {
    value: "announcement",
    label: "Announcement",
  },
];
export const stockTypeOption = [
  {
    value: "security",
    label: "Security",
  },
  {
    value: "sector",
    label: "Sector",
  },
];

export const menuTypeOption = [
  {
    value: "backend",
    label: "Backend",
  },
  {
    value: "pro-user",
    label: "Pro",
  },
  {
    value: "frontend",
    label: "Frontend",
  },
];

export const roleOption = [
  {
    value: "user",
    label: "User",
  },
  {
    value: "pro-user",
    label: "Pro User",
  },
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "superadmin",
    label: "Super Admin",
  },
  {
    value: "moderator",
    label: "Moderator",
  },
];

export const adminRoleOption = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "moderator",
    label: "Moderator",
  },
];

export const menuGroupOption = [
  {
    value: "toMenu",
    label: "Top Menu",
  },
  {
    value: "header",
    label: "Header",
  },
  {
    value: "footer",
    label: "Footer",
  },
  {
    value: "sidebar",
    label: "Sidebar",
  },
];

export const contentGroupOption = [
  {
    value: "homepage",
    label: "Home Page",
  },
  {
    value: "contact",
    label: "Contact",
  },
  {
    value: "pro",
    label: "pro",
  },
];

export const colorOptionsData = [
  "Schwarz",
  "Weiß",
  "Silber",
  "Blau",
  "Rot",
  "Grün",
  "Gelb",
  "Grau",
  "Lila",
  "Braun",
  "Sonstiges",
];
