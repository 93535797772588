import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Image, Popconfirm } from "antd";
import FileSaver from "file-saver";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillFilePdf, AiOutlineCloudDownload } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { BsFiletypeDocx } from "react-icons/bs";
import { Attachment } from "types/attachment";
import { getFileExtension, isAdmin } from "utils/global";
import { IMAGE_FALLBACK } from "utils/global/constants";

const WIDTH = 65 as const;
const HEIGHT = 54 as const;
const ICON_FONT = "50px" as const;

export default function UploadedListItem({
  file,
  onDelete,
  nameSuffix,
  forCustomerFileStatus,
}: {
  file: Attachment;
  onDelete?: (file: Attachment) => void;
  nameSuffix?: string;
  forCustomerFileStatus?: (file) => void;
}) {
  const { fileUrl, name, type } = file;
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(file.isForCustomer);

  useEffect(() => {
    setIsChecked(file.isForCustomer);
  }, [file.isForCustomer, setIsChecked]);

  const handleDownload = () => {
    FileSaver.saveAs(fileUrl, name);
  };

  const mimeType = type.split("/");
  const fileExtension = getFileExtension(name);

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
    const formData = {
      id: file.id,
      isForCustomer: e.target.checked,
    };
    !!forCustomerFileStatus && forCustomerFileStatus(formData);
  };

  return (
    <div className="uploaded-list-item justify-space-between">
      <div className="flex items-center gap-2">
        {mimeType[0] === "image" || fileExtension === ".heic" ? (
          <Image
            src={fileUrl}
            width={WIDTH}
            height={HEIGHT}
            style={{ objectFit: "contain" }}
            fallback={IMAGE_FALLBACK}
          />
        ) : mimeType[1] === "pdf" ? (
          <div
            className="flex justify-center items-center cursor-pointer"
            style={{
              width: WIDTH,
              height: HEIGHT,
            }}
            onClick={() => {
              window.open(fileUrl, "_blank");
            }}
          >
            <AiFillFilePdf fontSize={ICON_FONT} />
          </div>
        ) : (
          <div
            className="flex justify-center items-center"
            style={{
              width: WIDTH,
              height: HEIGHT,
            }}
          >
            <BsFiletypeDocx fontSize={ICON_FONT} />
          </div>
        )}
        <div className="flex-1">
          <p className="m-0">{name}</p>
          {nameSuffix && <p className="m-0 italic">{nameSuffix}</p>}
        </div>
      </div>
      <div className="flex items-center gap-2">
        {isAdmin() && !!forCustomerFileStatus && (
          <Checkbox onChange={handleChange} checked={isChecked} />
        )}
        <Button type="primary" onClick={handleDownload}>
          <AiOutlineCloudDownload />
        </Button>

        {!!onDelete && (
          <Popconfirm
            placement="leftBottom"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            title={t("general:confirmDocumentDelete")}
            onConfirm={() => onDelete(file)}
            okText={t("orders:yes")}
            cancelText={t("orders:no")}
          >
            <Button type="primary" style={{ background: "red" }}>
              <BiTrash />
            </Button>
          </Popconfirm>
        )}
      </div>
    </div>
  );
}
