import { Button } from "antd";
import WorkerParkedCar from "components/workerOrders/WorkerParkedCar";
import WorkerParkedCarList from "components/workerOrders/WorkerParkedCarList";
import { useInstallationMultiStatusUpdate } from "queryHook/installation";
import { useGetParkedCarList } from "queryHook/parkedCar";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineFilePdf } from "react-icons/ai";
import { useReactToPrint } from "react-to-print";
import { InstallationSchema } from "types/installation";
import VehicleLogOnCreationDay from "../VehicleLogOnCreationDay";

export default function VehiclesTab({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();

  const printRefVehicleLog = useRef<HTMLDivElement>(null);

  const { id, installationProtocol } = installation;
  const { data: parkedCars } = useGetParkedCarList(id);

  const { mutate } = useInstallationMultiStatusUpdate(id);

  const handlePrintVehicleLog = useReactToPrint({
    content: () => printRefVehicleLog.current,
    documentTitle: `Aufstellprotokoll_${installation.payment.paymentId}`,
    pageStyle: `
      @page {
         size: portrait;
      }
    `,
  });

  const handleStatusChange = () => {
    if (!installationProtocol) {
      mutate({
        installationProtocol: true,
        acceptOrder: installation.acceptOrder,
        rejectOrder: installation.rejectOrder,
        requestApproval: installation.requestApproval,
        permitGranted: installation.permitGranted,
        approvalSubmittedByCustomer: installation.approvalSubmittedByCustomer,
        erectSigns: installation.erectSigns,
        sendDocumentsToCustomer: installation.sendDocumentsToCustomer,
        collectSigns: installation.collectSigns,
        sendInvoice: installation.sendInvoice,
        invoicePaid: installation.invoicePaid,
        closeOrder: installation.closeOrder,
      });
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center gap-2">
        <h5 className="underline">{t("orders:modalTabs:vehicles")}</h5>
        <Button
          type="primary"
          onClick={() => {
            handleStatusChange();
            handlePrintVehicleLog();
          }}
        >
          <AiOutlineFilePdf />
        </Button>
      </div>
      <div className="flex flex-col gap-4">
        <WorkerParkedCar installationId={id} />
        <WorkerParkedCarList installationId={id} parkedCars={parkedCars} />
        <VehicleLogOnCreationDay
          ref={printRefVehicleLog}
          defaultValue={installation}
          parkedCars={parkedCars}
        />
      </div>
    </div>
  );
}
