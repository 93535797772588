import { message } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { AuthorizedService } from "shared/axios";
import { PaginatedResponse } from "types";
import { FileResponseType } from "types/attachment";
import { Authority } from "types/authority";

const END_POINT = "/authority";
const AUTHORITY_LIST_QUERY_KEY = "authority-list";
const AUTHORITY_DETAILS_QUERY_KEY = "authority-details";

export const useGetAuthoritiesList = (params) => {
  return useQuery<PaginatedResponse<Authority[]>>({
    queryKey: [AUTHORITY_LIST_QUERY_KEY, params],
    queryFn: async () => {
      const res = await new AuthorizedService().get(END_POINT, params);
      return res.data;
    },
  });
};

export const useGetAuthorityById = (authorityId: string) => {
  return useQuery<Authority>({
    queryKey: [AUTHORITY_DETAILS_QUERY_KEY, authorityId],
    queryFn: async () => {
      const res = await new AuthorizedService().get(
        `${END_POINT}/${authorityId}`
      );
      return res.data;
    },
    cacheTime: 0,
  });
};

export const usePostAuthority = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: FormData) => {
      return await new AuthorizedService().post(END_POINT, data);
    },
    onError: () => {
      message.error(t("authority:messages.creationFailed"));
    },
    onSettled: () => {
      queryClient.invalidateQueries([AUTHORITY_LIST_QUERY_KEY]);
    },
  });
};

export const useUpdateAuthority = (authorityId: string) => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: FormData) => {
      await new AuthorizedService().put(`${END_POINT}/${authorityId}`, data);
    },
    onSuccess: () => {
      message.success(t("authority:messages.updatedSuccessfully"));
      history.push("/authority/all");
    },
    onError: () => {
      message.error(t("authority:messages.updateFailed"));
    },
    onSettled: () => {
      queryClient.invalidateQueries([AUTHORITY_LIST_QUERY_KEY]);
    },
  });
};

export const useUploadAuthorityFiles = () => {
  const { t } = useTranslation();

  const { mutateAsync: uploadFiles, isLoading: uploadLoading } = useMutation({
    mutationFn: async (data: { attachmentId: string; formData: FormData }) => {
      return await new AuthorizedService().post(
        `${END_POINT}/${data.attachmentId}/attachment`,
        data.formData
      );
    },
    onError: () => {
      message.error(t("upload:uploadFail"));
    },
  });

  const handleUpload = async (attachmentId: string, attachments: File[]) => {
    const uploadPromises: Promise<any>[] = [];

    for (const attachment of attachments) {
      const attachmentsFormData = new FormData();
      attachmentsFormData.append("file", attachment);
      uploadPromises.push(
        uploadFiles({
          attachmentId,
          formData: attachmentsFormData,
        })
      );
    }

    const res = await Promise.all(uploadPromises);

    const result: FileResponseType[] = [];
    res.forEach((file) => {
      const { name, type, size, path } = file.data;
      result.push({
        name,
        type,
        size,
        path,
      });
    });

    return result;
  };

  return { handleUpload, uploadLoading };
};

export const useDeleteAuthorityFile = (authorityId: string) => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: async (fileId: string) => {
      await new AuthorizedService().delete(
        `${END_POINT}/${authorityId}/attachment/${fileId}`
      );
    },
    onSuccess: () => {
      message.success(t("general:deleteSuccess"));
    },
    onError: () => {
      message.error(t("general:deleteFail"));
    },
  });
};

export const useDeleteAuthority = (authorityId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await new AuthorizedService().delete(`${END_POINT}/${authorityId}`);
    },
    onSuccess: () => {
      message.success(t("authority:messages.deletedSuccessfully"));
    },
    onError: () => {
      message.error(t("authority:messages.deletionFailed"));
    },
    onSettled: () => {
      queryClient.invalidateQueries([AUTHORITY_LIST_QUERY_KEY]);
    },
  });
};
