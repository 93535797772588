import i18n from "i18n";
import { InstallationSchema } from "types/installation";
import { YES } from "./constants";

const { t } = i18n;

export const getUpdatedIsTwoSiteBlocking = (
  installation: InstallationSchema
) => {
  let updatedIsTwoSiteBlocking = installation.isTwoSiteBlock;
  if (installation.additionalInformation?.additionalIsTwoSiteBlock) {
    updatedIsTwoSiteBlocking =
      installation.additionalInformation.additionalIsTwoSiteBlock === YES;
  }

  return updatedIsTwoSiteBlocking;
};

export const getUpdatedWithOutsideLift = (installation: InstallationSchema) => {
  let updatedWithOutsideLift = installation.withOutsideLift;
  if (installation.additionalInformation?.additionalWithOutsideLift) {
    updatedWithOutsideLift =
      installation.additionalInformation.additionalWithOutsideLift === YES;
  }

  return updatedWithOutsideLift;
};

export const getBarrierDetailsCustomerAddress = (
  installation: InstallationSchema
) => {
  const customerAddress =
    (installation.additionalInformation?.additionalStreetAndHouseNumber ??
      installation.streetAndHouseNumber) +
    ", " +
    (installation.additionalInformation?.additionalCity ??
      installation.city.name);

  return customerAddress;
};

export const getBarrierDetailsCustomerAddressWithTwoSideBlocking = (
  installation: InstallationSchema
) => {
  const customerAddress = getBarrierDetailsCustomerAddress(installation);
  const updatedIsTwoSiteBlocking = getUpdatedIsTwoSiteBlocking(installation);

  const formattedCustomerAddress =
    customerAddress +
    " / " +
    (installation.additionalInformation?.additionalLengthOfBoard ??
      installation.lengthOfBoard.split(" ")[0]) +
    (updatedIsTwoSiteBlocking ? ` (${t("orders:onBothSides")})` : "");

  return formattedCustomerAddress;
};

export function getBarrierDetailsCity(installation: InstallationSchema) {
  return (
    installation.barrierDetails?.city ??
    installation.streetAndHouseNumber + ", " + installation.city.name
  );
}

export function getIsWithPermission(installation: InstallationSchema) {
  let withPermission = installation.isWithPermission;
  if (installation.additionalInformation?.additionalIsWithPermission) {
    withPermission =
      installation.additionalInformation.additionalIsWithPermission === YES;
  }

  return withPermission;
}
