import { Form, Input } from "antd";
import { useShowInputContext } from "context/ShowInputContext";
import { useTranslation } from "react-i18next";
import { PriceTableSchema, UpdatedPrice } from "types/installation";

export default function PriceTable({
  priceTable,
  updatedPrice,
}: {
  priceTable: PriceTableSchema[];
  updatedPrice: UpdatedPrice | null;
}) {
  const { t } = useTranslation();
  const { showInput, isLoading } = useShowInputContext();

  const originalTotalPrice = priceTable[priceTable.length - 1]?.totalPrice;
  const updatedTotalPrice = updatedPrice?.totalPrice ?? originalTotalPrice;

  return (
    <div>
      <table className="price-table">
        <thead>
          <tr>
            <th>{t("orders:priceTable.items")}</th>
            <th>{t("orders:priceTable.numberOfDays")}</th>
            <th className="break-line">{t("orders:priceTable.basePrice")}</th>
            <th className="break-line">{t("orders:priceTable.total")}</th>
          </tr>
        </thead>
        <tbody>
          {priceTable.map((price) => {
            if (!price.enabled) return null;
            return (
              <tr key={price.id}>
                <td>{price.article}</td>
                <td>{price.quantity ? price.quantity : ""}</td>
                <td>
                  {price.unitPrice ? `${price.unitPrice.toFixed(2)}€` : ""}
                </td>
                <td>
                  {price.netPrice ? (
                    <>
                      <div>{price.totalPrice.toFixed(2)}€</div>
                      <div>
                        ({price.netPrice.toFixed(2)}€ {t("general:netPrice")})
                      </div>
                    </>
                  ) : (
                    `${price.totalPrice.toFixed(2)}€`
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="updated-price">
        <label htmlFor="totalPrice" className="font-600">
          {t("orders:priceTable.updatedTotal")}:
        </label>
        {showInput ? (
          <Form.Item name="totalPrice" initialValue={updatedTotalPrice}>
            <Input type="number" disabled={isLoading} />
          </Form.Item>
        ) : (
          <div
            className={`font-600 ${
              originalTotalPrice === updatedTotalPrice ? "" : "text-red"
            }`}
          >
            {updatedTotalPrice}€
          </div>
        )}
      </div>
    </div>
  );
}
