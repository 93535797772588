import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm, message } from "antd";
import UploadedListItem from "components/reusable/UploadListItem";
import { useEffect, useRef, useState } from "react";
import {
  Accept,
  ErrorCode,
  FileError,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import { useTranslation } from "react-i18next";
import { AiFillFileAdd } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { Attachment } from "types/attachment";
import {
  DEFAULT_ACCEPTED_FILE_TYPE,
  MAX_NO_OF_FILES_AT_ONCE,
  MAX_UPLOAD_FILE_SIZE,
} from "utils/global/constants";

type Props = {
  maxSize?: number;
  maxFiles?: number;
  acceptedFileType?: Accept;
  setValue: (files: File[]) => void;
  initialFiles?: Attachment[];
  deleteUploadedFile?: (id: string) => Promise<void>;
  clearFileInputToggle?: boolean; // Clear files on toggle of this prop
};

export default function FileSelector({
  acceptedFileType = DEFAULT_ACCEPTED_FILE_TYPE,
  maxSize = MAX_UPLOAD_FILE_SIZE,
  maxFiles = MAX_NO_OF_FILES_AT_ONCE,
  initialFiles = [],
  clearFileInputToggle,
  deleteUploadedFile,
  setValue,
}: Props) {
  const [files, setFiles] = useState<File[]>([]);

  // Files that were already uploaded (in edit page)
  const [uploadedFiles, setUploadedFiles] =
    useState<Attachment[]>(initialFiles);

  const isFirstMount = useRef(true);

  const { t } = useTranslation();

  const fileSizeValidator = (file: File): FileError | FileError[] | null => {
    const fileSize = file.size / 1024 / 1024; // size in MB
    if (fileSize > maxSize) {
      return {
        code: ErrorCode.FileTooLarge,
        message: t("upload:error:fileTooLarge", { fileName: file.name }),
      };
    }
    return null;
  };

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const hasManyFilesError = fileRejections.some((e) => {
      return e.errors.some((error) => {
        return error.code === ErrorCode.TooManyFiles;
      });
    });

    if (hasManyFilesError) {
      message.error(t("upload:error:tooManyFiles"));
      return;
    }

    if (fileRejections.length > 0) {
      fileRejections.map((e) => {
        e.errors.forEach((error) => {
          if (error.code === ErrorCode.FileInvalidType) {
            message.error(t("upload:error:invalidFileType"));
          } else {
            message.error(error.message);
          }
        });
      });
      return;
    }

    setFiles([...files, ...acceptedFiles]);
  };

  useEffect(() => {
    setValue(files);
  }, [files, setValue]);

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
    } else {
      setFiles([]);
    }
  }, [clearFileInputToggle]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileType,
    validator: fileSizeValidator,
    onDrop,
    maxFiles,
  });

  const removeUploadedFile = async (fileToBeRemoved: Attachment) => {
    await deleteUploadedFile?.(fileToBeRemoved.id);

    const newUploadedFiles = [...uploadedFiles];
    newUploadedFiles.splice(uploadedFiles.indexOf(fileToBeRemoved), 1);
    setUploadedFiles(newUploadedFiles);
  };

  const removeFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <div className="responsive-two-column">
      <div>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <div>{t("upload:dragDropMsg", { maxSize })}</div>
          <div style={{ fontSize: "3rem", lineHeight: "1" }}>
            <AiFillFileAdd />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        {uploadedFiles?.map((file) => {
          return (
            <UploadedListItem
              key={file.id}
              file={file}
              onDelete={removeUploadedFile}
            />
          );
        })}
        {files?.map((file, index) => (
          <div key={index} className="uploaded-list-item">
            <div>
              <span>{file.name}</span>
            </div>
            <Popconfirm
              placement="leftBottom"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              title={t("general:confirmDocumentDelete")}
              onConfirm={() => removeFile(index)}
              okText={t("orders:yes")}
              cancelText={t("orders:no")}
            >
              <Button type="primary" style={{ background: "red" }}>
                <BiTrash />
              </Button>
            </Popconfirm>
          </div>
        ))}
      </div>
    </div>
  );
}
