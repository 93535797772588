import type { ProColumns } from "@ant-design/pro-table";
import { ColumnsStateType } from "@ant-design/pro-table/lib/typing";
import { Button, Collapse, Spin } from "antd";
import AntdTablePro from "components/antdTablePro";
import ShowInputProvider from "context/ShowInputContext";
import { debounce } from "lodash";
import { useGetWorkerInstallations } from "queryHook/workerOrder";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventType, InstallationSchema } from "types/installation";
import { formatDateYYYYMMDD, getGoogleMapLink } from "utils/global";
import WorkerModal from "./WorkerModal";

export type WorkerInstallationsFilterParams = {
  page: number;
  size: number;
  search: string;
  date: string | undefined;
  isToday: boolean;
};

const columnsState: ColumnsStateType = {
  persistenceKey: "workerOrderTable",
  persistenceType: "localStorage",
};

export default function WorkerOrderTable({
  pageSize = 10,
  isToday = false,
  event,
}: {
  pageSize?: number;
  isToday?: boolean;
  event: EventType;
}) {
  const { t } = useTranslation();

  const [filterParams, setFilterParams] =
    useState<WorkerInstallationsFilterParams>({
      page: 1,
      size: pageSize,
      search: "",
      date: formatDateYYYYMMDD(new Date()),
      isToday,
    });

  const [state, setState] = useState<{
    formModal: boolean;
    defaultValue: InstallationSchema | null;
  }>({
    formModal: false,
    defaultValue: null,
  });

  const { isLoading, data } = useGetWorkerInstallations({
    ...filterParams,
    event,
  });

  const isSetup = event === "setup";

  const collapseHeader =
    (isSetup ? t("orders:setup") : t("orders:pickup")) +
    (data?.meta.totalItems ? ` (${data?.meta.totalItems})` : "");

  useEffect(() => {
    // Update defaulValue in model when data is invalidated
    setState((prev) => {
      const selectedInstallation = data?.items.find(
        (installation) => prev.defaultValue?.id === installation.id
      );

      if (!selectedInstallation) {
        return { ...prev };
      }

      return {
        ...prev,
        defaultValue: selectedInstallation,
      };
    });
  }, [data?.items]);

  if (isLoading) return <Spin />;

  const columns: ProColumns[] = [
    {
      key: "workerOrderNumber",
      title: t("orders:workerOrderNumber"),
      dataIndex: "workerOrderNumber",
      render: (_, row: InstallationSchema) => {
        return (
          <div>
            {isSetup ? (
              <div>{row.workerOrderNumber || "-"}</div>
            ) : (
              <div>{row.workerPickupOrderNumber || "-"}</div>
            )}
          </div>
        );
      },
    },
    {
      key: "bookingNumber",
      title: t("menu:Order"),
      render: (_, row: InstallationSchema) => {
        return (
          <div
            style={{
              paddingBlock: "1rem",
            }}
          >
            <a
              href={getGoogleMapLink(row)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {isSetup ? (
                <div>{row.setupEventName}</div>
              ) : (
                <div>{row.pickupEventName}</div>
              )}
            </a>
          </div>
        );
      },
    },
    {
      key: "action",
      title: t("orders:action"),
      valueType: "option",
      render: (_, row: InstallationSchema, index) => [
        <div key={index}>
          <Button
            type="primary"
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                formModal: true,
                defaultValue: row,
              }))
            }
          >
            {t("main:view")}
          </Button>
        </div>,
      ],
    },
  ];

  const getSearchKeywordsDeb = debounce((value) => {
    setFilterParams({ ...filterParams, search: value });
  }, 500);

  return (
    <Collapse>
      <Collapse.Panel
        header={collapseHeader}
        key={1}
        className="worker-table-collapse"
      >
        <AntdTablePro
          columns={columns}
          rowKey="id"
          dataSource={data?.items}
          rowClassName={(installation: InstallationSchema) => {
            if (isSetup && !!installation.setupDoneDateTime)
              return "installation-bg-green";
            if (!isSetup && !!installation.pickupDoneDateTime)
              return "installation-bg-green";
            return "installation-bg-yellow";
          }}
          columnsState={columnsState}
          toolBarRender={false}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            pageSize: filterParams?.size,
            total: data?.count,
            onChange: (page, pageSize) => {
              setFilterParams({
                ...filterParams,
                page: page,
                size: pageSize,
              });
            },
          }}
          inputElement={{
            placeholder: t("main:search"),
            handleChange: (e) => getSearchKeywordsDeb(e.target.value),
            handleSearch: (value) =>
              setFilterParams({ ...filterParams, search: value }),
          }}
        />
        {state.formModal && (
          <ShowInputProvider>
            <WorkerModal
              visible={state.formModal}
              installation={state.defaultValue!}
              modalText={`${t("orders:viewOrders")} ${
                state.defaultValue?.payment.paymentId || ""
              }`}
              event={event}
              onHide={() =>
                setState((prevState) => ({
                  ...prevState,
                  formModal: false,
                }))
              }
              params={{
                ...filterParams,
                event,
              }}
            />
          </ShowInputProvider>
        )}
      </Collapse.Panel>
    </Collapse>
  );
}
