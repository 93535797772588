import { Layout } from "antd";

// const footerStyle = {
//   padding: "20px 30px 18px",
//   color: "rgba(0, 0, 0, 0.65)",
//   fontSize: "14px",
//   background: "rgba(255, 255, 255, .90)",
//   width: "100%",
//   boxShadow: "0 -5px 10px rgba(146,153,184, 0.05)",
// };

const Index = () => {
  return (
    <Layout className="atbd-main-layout">
      {/* <Content>
				<Footer className="admin-footer" style={footerStyle}>
					<Row>
						<Col md={12} xs={24}>
							<span className="admin-footer__copyright">
								2022 © Salman
							</span>
						</Col>
					</Row>
				</Footer>
			</Content> */}
    </Layout>
  );
};
export default Index;
