import LayoutWrapper from "components/reusable/LayoutWrapper";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { menuData } from "utils/global/menu";
interface Props extends RouteProps {
  title: string;
  title_np: string;
  exact: boolean;
  component: any;
  isAuthenticated: boolean;
  path: string;
  roles: Array<string>;
}

const PrivateRoute = ({ component: Component, ...props }: Props) => {
  const { isAuthenticated, path, ...rest } = props;

  return (
    <>
      {menuData?.items?.length > 0 && isAuthenticated ? (
        <Route
          {...rest}
          path={path}
          render={(props) => {
            let isRouteValid = menuData.items.find((item) => {
              return item.url == path || "/dashboard" == path;
            });
            if (isRouteValid) {
              return (
                <LayoutWrapper>
                  <Component {...props} />
                </LayoutWrapper>
              );
            }
            return (
              <Redirect
                to={{
                  pathname: "/unauthorized",
                  state: { from: props.location },
                }}
              />
            );
          }}
        />
      ) : (
        <Route
          {...rest}
          path={path}
          render={(props) => <Component {...props} />}
        />
      )}
    </>
  );
};

export default PrivateRoute;
