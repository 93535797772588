import { Divider, Form, FormInstance } from "antd";
import { usePostWorker } from "queryHook/worker";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronLeft } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import WorkerForm from "../WorkerForm";
import { cleanData } from "utils/global";

export default function AddAuthority() {
  const { t } = useTranslation();
  const history = useHistory();
  const formRef = useRef<FormInstance>(null);

  const { mutateAsync, isLoading } = usePostWorker(formRef);

  const onSubmit = async (data) => {
    const newData = cleanData(data);
    await mutateAsync(newData);

    history.push("/worker/all");
  };

  return (
    <Form
      autoComplete="off"
      layout="vertical"
      style={{ padding: "1rem", background: "white" }}
      onFinish={onSubmit}
      ref={formRef}
    >
      <h5>
        <Link to="/worker/all" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
          <BiChevronLeft />
          {t("worker:addWorker")}
        </Link>
      </h5>
      <Divider orientation="left" style={{ marginTop: 0 }} />
      <WorkerForm isLoading={isLoading} />
    </Form>
  );
}
