import { initReactQueryAuth } from "components";
import { authToken } from "utils/global";
import jwt_decode from "jwt-decode";
import { AxiosService } from "shared";
import { isEmpty } from "lodash";
import { message } from "antd";

const endPoint = "/auth-admin/signin";

export type LoginCredentials = {
  email: string;
  password: string;
};

export interface User {
  id: string;
  email: string;
  name?: string;
  firstName?: string;
  image?: string;
  lastName?: string;
  role: "admin" | "worker";
}

async function loadUser() {
  try {
    let user: any = null;

    if (!isEmpty(authToken.getToken())) {
      user = jwt_decode(authToken.getToken());
      const { exp } = user;
      const expirationTime = exp * 1000 - 60000;
      if (Date.now() >= expirationTime) {
        authToken.clearToken();
        window.location.href = "/login";
      }
    }

    return user;
  } catch (error) {
    console.log();
  }
}

async function loginFn(data: LoginCredentials) {
  try {
    const response: any = await new AxiosService().post(endPoint, data);
    return response;
  } catch (error: any) {
    if (!isEmpty(error?.data)) {
      message.error(error?.data?.error);
    }
  }
}

async function logoutFn() {
  await authToken.clearToken();
}

const authConfig: any = {
  loadUser,
  loginFn,
  logoutFn,
};

const { AuthProvider, AuthConsumer, useAuth } = initReactQueryAuth<
  User,
  any,
  LoginCredentials
>(authConfig);

export { AuthProvider, AuthConsumer, useAuth };
