import { message } from "antd";
import { t } from "i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AuthorizedService } from "shared/axios";
import { PaginatedResponse } from "types";

const END_POINT = "/city";
const CITY_LIST_QUERY_KEY = "city-list";

export type City = {
  basePrice: number;
  createdAt: string;
  id: string;
  lowestPrice: number;
  name: string;
  permissionPrice: number;
  withOutsideLiftPrice: number;
  slug: string;
  updatedAt: string;
};

export const useGetCityList = () => {
  return useQuery<City[]>({
    queryKey: [CITY_LIST_QUERY_KEY],
    queryFn: async () => {
      const res = await new AuthorizedService().get(`${END_POINT}/all`);
      return res.data;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useGetCityListPagination = (params) => {
  return useQuery<PaginatedResponse<City>>({
    queryKey: [CITY_LIST_QUERY_KEY, params],
    queryFn: async () => {
      const res = await new AuthorizedService().get(END_POINT, params);
      return res.data;
    },
  });
};

export type CityPriceRequestBody =
  | {
      basePrice: number | null;
    }
  | {
      permissionPrice: number | null;
    }
  | {
      withOutsideLiftPrice: number | null;
    };

export const useUpdateCityPriceAmount = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: CityPriceRequestBody) =>
      new AuthorizedService()
        .put(`${END_POINT}/${id}`, data)
        .then((res: any) => res),
    {
      onSuccess: (data) => {
        if (data) {
          message.success(t("general:updatedSuccess"));
        }
      },
      onError: () => {
        message.error(t("general:updateFail"));
      },
      onSettled: () => {
        queryClient.invalidateQueries(CITY_LIST_QUERY_KEY);
      },
    }
  );
};
