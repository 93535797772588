import { InstallationSchema } from "types/installation";
import PaymentStatus from "../PaymentStatus";
import { useTranslation } from "react-i18next";

export default function PaymentTab({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <h5 className="underline">{t("orders:modalTabs:payment")}</h5>
      </div>
      <div>
        <PaymentStatus installation={installation} />
      </div>
    </div>
  );
}
