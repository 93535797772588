export default function CollapsePanelHeader({
  textKey,
  totalNumber,
}: {
  textKey: string;
  totalNumber?: string;
}) {
  return (
    <>
      <span className="underline mt-5" style={{ fontSize: "1.25rem" }}>
        {textKey}
      </span>

      {totalNumber !== undefined && (
        <span style={{ fontSize: "1.25rem", marginLeft: "8px", color: "red" }}>
          ({totalNumber})
        </span>
      )}
    </>
  );
}
