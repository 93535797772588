import WorkerOrderTable from "components/workerOrders/WorkerOrderTable";
import { useTranslation } from "react-i18next";

function Index() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      <div>
        <h5 className="underline">{t("general:today")}:</h5>
        <WorkerOrderTable isToday={true} event="setup" />
        <WorkerOrderTable isToday={true} event="pickup" />
      </div>
      <div>
        <h5 className="underline">{t("general:later")}:</h5>
        <WorkerOrderTable event="setup" />
        <WorkerOrderTable event="pickup" />
      </div>
    </div>
  );
}

export default Index;
