import { Button } from "antd";
import React from "react";
import { BiMailSend } from "react-icons/bi";

interface Props {
  setModalOpenClose: () => void;
}

const EmailButton = ({ setModalOpenClose }: Props) => {
  return (
    <Button
      type="primary"
      className="modal-button"
      style={{ margin: "0px 0px 10px 10px" }}
      onClick={setModalOpenClose}
    >
      <BiMailSend />
    </Button>
  );
};

export default EmailButton;
