import { WorkerSchema } from "queryHook/worker";
import { MULTI_STATUS } from "utils/global/constants";
import { Attachment } from "./attachment";

export type AdditionalInstallationInformation = {
  // installation details
  additionalPurpose: string;
  additionalStatus: string;
  additionalTimeFrom: string;
  additionalTimeTill: string;
  additionalLengthOfBoard: string;
  additionalStreetAndHouseNumber: string;
  additionalZipCode: string;
  additionalCity: string;
  additionalIsTwoSiteBlock: string;
  additionalIsWithPermission: string;
  additionalWithOutsideLift: string;

  // contact details
  additionalFirstName: string;
  additionalLastName: string;
  additionalEmail: string;
  additionalCompanyName: string;
  additionalContactStreetAndHouseNumber: string;
  additionalContactCityAndZipCode: string;
  additionalNotes: string;
  additionalTelephoneNumber: string;

  commentHVZ: string;
};

export type BarrierDetailsSchema = {
  // Address
  halteverbot24: string;
  city: string;
};

export type LicensePlates = {
  licensePlate1: string;
  licensePlate2: string;
  licensePlate3: string;
  licensePlate4: string;
  licensePlate5: string;
  licensePlate6: string;
  licensePlate7: string;
  licensePlate8: string;
  licensePlate9: string;
  licensePlate10: string;
};

export enum Purpose {
  MOVE = "umzug",
  CONSTRUCTION_SITE = "baustelle",
  OTHER = "sonstiges",
  DELIVERY = "lieferung",
  FILM_AND_EVENTS = "film&events",
}

export type InstallationSchema = {
  id: string;
  createdAt: string;
  updatedAt: string;
  street?: string;
  houseNumber?: string;
  streetAndHouseNumber: string;
  zipCode: string;
  dateRangeFrom: string;
  dateRangeTo: string;
  timeFrom: string;
  timeTill: string;
  lengthOfBoard: string;
  isTwoSiteBlock: boolean;
  isWithPermission: boolean;
  withOutsideLift: boolean;
  purpose: Purpose;
  status: string;
  statusRemarks: any;
  city: City;
  customer: Customer;
  payment: Payment;
  additionalInformation: Partial<AdditionalInstallationInformation> | null;
  licensePlates: Partial<LicensePlates> | null;
  priceTable: PriceTableSchema[] | null;
  updatedPrice: UpdatedPrice | null;
  barrierDetails: BarrierDetailsSchema | null;
  setupDate: string;
  setupTime: string;
  pickupDate: string;
  updatedDateRangeFrom: string;
  updatedDateRangeTo: string;
  invoiceUploadedByClient: boolean;
  invoicePaidByClient: boolean;
  taxBooking: boolean;
  assignedTo: WorkerSchema | null;
  pickupAssignedTo: WorkerSchema | null;
  workerOrderNumber: number | null;
  workerPickupOrderNumber: number | null;
  setupEventName: string;
  pickupEventName: string;
  setupDoneDateTime: string | null;
  pickupDoneDateTime: string | null;
  notice: string;
  workerNotice: string;
  workerInstructionNumber: number | null;
  publicAttachments: Attachment[] | null;
  parkedCar: ParkedCars[];
} & InstallationStatus &
  InstallationStatusDateTime;

export type InstallationStatus = {
  acceptOrder: boolean;
  rejectOrder: boolean;
  requestApproval: boolean;
  permitGranted: boolean;
  approvalSubmittedByCustomer: boolean;
  erectSigns: boolean;
  installationProtocol: boolean;
  sendDocumentsToCustomer: boolean;
  collectSigns: boolean;
  sendInvoice: boolean;
  invoicePaid: boolean;
  closeOrder: boolean;
};

export type InstallationStatusDateTime = {
  acceptOrderDateTime: string | null;
  rejectOrderDateTime: string | null;
  requestApprovalDateTime: string | null;
  permitGrantedDateTime: string | null;
  approvalSubmittedByCustomerDateTime: string | null;
  erectSignsDateTime: string | null;
  installationProtocolDateTime: string | null;
  sendDocumentsToCustomerDateTime: string | null;
  collectSignsDateTime: string | null;
  sendInvoiceDateTime: string | null;
  invoicePaidDateTime: string | null;
  closeOrderDateTime: string | null;
};

export type City = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  zipcode: any;
  basePrice: number;
  slug: string;
  permissionPrice: number;
};

export type Customer = {
  id: string;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  telephoneNumber: string;
  city?: string;
  zipCode?: string;
  cityAndZipCode: string;
  street?: string;
  houseNumber?: string;
  streetAndHouseNumber: string;
  notes: string;
};

export type Payment = {
  id: string;
  createdAt: string;
  updatedAt: string;
  details: {
    order: {
      amount: string;
    };
  };
  paymentId: string;
  paymentType: PaymentType;
};

export type MultiStatus = typeof MULTI_STATUS[number];

export type PriceTableSchema = {
  id: number;
  article: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  enabled: boolean;
  netPrice?: number;
};

export type UpdatedPrice = {
  totalPrice: number;
};

export type OrderModelForm = AdditionalInstallationInformation &
  LicensePlates &
  UpdatedPrice & {
    barrierDetailsCustomer: string;
    barrierDetailsHalteverbot24: string;
    barrierDetailsCity: string;
    barrierDetailsSetupDate: string;
    barrierDetailsSetupTime: string;
    barrierDetailsPickupDate: string;
    updatedDateRangeFrom: string;
    updatedDateRangeTo: string;
    notice: string;
    workerNotice: string;
    workerInstructionNumber: number;
  };

export type EventType = "setup" | "pickup";

export type ParkedCars = {
  id: string;
  carColor: string;
  licensePlate: string;
  brand: {
    id: string;
    name: string;
  };
  createdAt: string;
  updatedAt: string;
};

export enum PaymentType {
  BOOKED = "booked",
  PREPAYMENT = "prepayment",
  PAYED = "payed",
}

export enum CityPriceType {
  BASEPRICE = "basePrice",
  PERMISSIONPRICE = "permissionPrice",
  WITHOUTSIDELIFTPRICE = "withOutsideLiftPrice",
}
