import { InstallationSchema, PaymentType } from "types/installation";
import { ADMIN_ROLE, WORKER_ROLE } from "./constants";
import { checkRoleExists } from "./role-check";
import { t } from "i18next";

export const toJson = (res) => {
  if (typeof res === "object") {
    return JSON.parse(JSON.stringify(res));
  }
  return JSON.parse(JSON.stringify({}));
};

function buildFormData(formData: any, data: any, parentKey: any = null) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob) &&
    !(Array.isArray(data) && !data.length)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

export function getBase64(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}

export function findCommonItems(arr1, arr2) {
  return arr1?.some((v) => arr2?.indexOf(v) !== -1);
}

/**
 *
 * @param date Expected input in the form of YYYY-MM-DD
 * @returns In the form of DD.MM.YYYY
 */
export const formattedDate = (date: string) => {
  const reversedArray = date.split("-").reverse();
  return reversedArray.join(".");
};

/**
 *
 * @param time Expected input in the form of hh:mm
 * @returns In the form of hh
 */
export const formattedTime = (time: string) => {
  const splittedTime = time.split(":");
  if (splittedTime[1] === "00") return splittedTime[0];

  return time;
};

/**
 *
 * @param date Expected Date Object
 * @returns In the form of YYYY-MM-DD HH:mm:ss
 */
export const formattedDateTime = (d: Date | string | null) => {
  if (!d) return null;
  const date = new Date(d);
  const formattedDate = formatDateYYYYMMDD(date);
  const formattedTime = date.toLocaleTimeString().substring(0, 5);

  return formattedDate + " " + formattedTime;
};

/**
 * Removes key value with false values from an object
 * @param {Object} formData any object
 * @returns cleaned object with only truthy values
 */
export const cleanData = <T>(formData: T): Partial<T> => {
  const cleanObj: Partial<T> = {};
  for (const key in formData) {
    if (Object.prototype.hasOwnProperty.call(formData, key)) {
      const newVal = formData[key];
      if (newVal) {
        cleanObj[key] = newVal;
      }
    }
  }
  return cleanObj;
};

/**
 *
 * @param d Date String. Eg: string in format YYYY-MM-DD
 * @param diff No. of days difference from given date
 * If inputs are d = 2023-06-08 and diff = 2
 * It returns '2023-06-10T00:00:00.000Z'
 */
export const getRelativeTime = (d: string, diff: number) => {
  const date = new Date(d);
  date.setDate(date.getDate() + diff);
  return date.toISOString();
};

/**
 *
 * @param date Expected input in the form of Date object
 * @returns In the form of YYYY-MM-DD
 */
export const formatDateYYYYMMDD = (d: Date | string) => {
  const date = new Date(d);
  let month = "" + (date.getMonth() + 1);
  let day = "" + date.getDate();
  let year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const isSunday = (d: string) => {
  const date = new Date(d);
  return date.getDay() === 0;
};

/**
 * Valid example: 2023-01-10
 */
export const validateDate = (_, value: string) => {
  const dateRegex = new RegExp(/^\d{4}-\d{2}-\d{2}$/);

  if (!dateRegex.test(value)) {
    return Promise.reject(t("warnings:invalidValue"));
  }

  const date = new Date(value);
  if (date.toString() === "Invalid Date") {
    return Promise.reject(t("warnings:invalidValue"));
  }

  const finalDateString = date.toISOString().substring(0, 10);

  if (value !== finalDateString) {
    return Promise.reject(t("warnings:invalidValue"));
  }

  return Promise.resolve();
};

/**
 * Valid example: 10:30
 * @returns Regex for hh:mm
 */
export const getTimeRegex = () => {
  return new RegExp(/^\d{2}:\d{2}$/);
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isAdmin() {
  return checkRoleExists([ADMIN_ROLE]);
}

export function isWorker() {
  return checkRoleExists([WORKER_ROLE]);
}

export function getGoogleMapLink(row: InstallationSchema) {
  const zipCode = row.additionalInformation?.additionalZipCode || row.zipCode;
  const streetAndHouseNumber =
    row.additionalInformation?.additionalStreetAndHouseNumber ||
    row.streetAndHouseNumber;
  const city = row.additionalInformation?.additionalCity || row.city.name;

  return `https://www.google.de/maps/place/${streetAndHouseNumber}, ${
    zipCode ? `${zipCode} ` : ""
  } ${city}`;
}

export const getFileExtension = (fileName: string) => {
  const lastIndexOfDot = fileName.lastIndexOf(".");
  return fileName.substring(lastIndexOfDot);
};

/**
 *
 * @param dateFromRange Expected input in the form of DD.MM.YYYY
 * @param dateTRange Expected input in the form of DD.MM.YYYY
 * @returns In the form of if in single day date range then DD.MM.YYYY else DD.MM.YYYY - DD.MM.YYYY
 */
export const formattedDateForSingleAndMultipleDays = (
  dateFromRange: string,
  dateToRange: string
) => {
  return dateFromRange === dateToRange
    ? dateFromRange
    : `${dateFromRange} - ${dateToRange}`;
};

/**
 *
 * @param lengthOfBoard Expected input value to be a string contain the length of the board
 * @returns In the form of length in m
 */
export const extractLengthOfBoard = (lengthOfBoard: string) => {
  const match = lengthOfBoard.match(/(\d+)(m)?/);

  if (match) {
    const lengthOfBoardNumeric = match[1];
    const unit = match[2] || "";
    return `${lengthOfBoardNumeric}${unit}`;
  } else {
    return lengthOfBoard;
  }
};

/**
 *
 * @param date Expected Date Object
 * @returns In the form of YYYY-MM-DD HH:mm:ss
 */
export const formattedExtractTime = (d: Date | string | null) => {
  if (!d) return "-:--";
  const date = new Date(d);
  const formattedTime = date.toLocaleTimeString().substring(0, 5);

  return formattedTime || "-:--";
};

export const getPaymentMethodDescription = (paymentType: PaymentType) => {
  switch (paymentType) {
    case PaymentType.PAYED:
      return "Paid (PayPal)";
    case PaymentType.PREPAYMENT:
      return "Open (prepayment)";
    case PaymentType.BOOKED:
      return "open (Vorkasse)";
    default:
      return "keine";
  }
};

export const stringTemplatingReplaceAll = (
  original: string,
  params: {
    pattern: string | undefined;
    value: string | undefined;
  }[]
) => {
  if (params.length === 0) return original;

  let result: string = original;
  for (const param of params) {
    const { pattern, value } = param;
    if (!pattern || !value) continue;

    result = result.replaceAll(`{${pattern}}`, value);
  }
  return result;
};
