import { Button, Form, Input } from "antd";
import { useCustomizableUpdate } from "queryHook/customizable";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiPencil, BiSave } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { Customizable } from "types/customizable";

type ReplaceableRuleFormSchema = { [customizableKey: string]: string };

export default function ReplaceableRuleForm({
  customizableKey,
  customizable,
}: {
  customizableKey: string;
  customizable: Customizable;
}) {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);

  const [form] = Form.useForm<ReplaceableRuleFormSchema>();

  const { mutate, isLoading } = useCustomizableUpdate(customizable.id);

  const onSubmit = (data: ReplaceableRuleFormSchema) => {
    mutate(
      {
        key: customizableKey,
        value: data[customizableKey] || null,
      },
      {
        onSuccess: () => {
          setEditMode(false);
          form.getFieldInstance(customizableKey).blur();
        },
      }
    );
  };

  return (
    <Form
      onFinish={onSubmit}
      layout="vertical"
      form={form}
      style={{ padding: "1rem", background: "white" }}
    >
      <Form.Item
        name={customizableKey}
        label={t(`customizable:${customizableKey}`)}
        initialValue={customizable.value}
      >
        <Input style={{ pointerEvents: editMode ? "auto" : "none" }} />
      </Form.Item>

      <div className="flex justify-end">
        {editMode ? (
          <div className="flex gap-2">
            <Button
              key="save"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              <BiSave />
            </Button>
            <Button
              key="cancel"
              type="default"
              className="btn-outline"
              onClick={() => setEditMode(false)}
            >
              <RxCross2 />
            </Button>
          </div>
        ) : (
          <Button
            key="edit"
            type="primary"
            htmlType="button"
            onClick={() => setEditMode(true)}
          >
            <BiPencil />
          </Button>
        )}
      </div>
    </Form>
  );
}
