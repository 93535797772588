import Upload from "components/reusable/Upload";
import {
  useDeleteWorkerFile,
  useGetAllWorkerUploadedFiles,
  useWorkerFileUpload,
} from "queryHook/workerAttachment";
import { Attachment } from "types/attachment";

export default function WorkerUpload({
  installationId,
}: {
  installationId: string;
}) {
  const { mutateAsync: uploadFile, isLoading } =
    useWorkerFileUpload(installationId);

  const { data } = useGetAllWorkerUploadedFiles(installationId);

  const { mutate: deleteFile } = useDeleteWorkerFile(installationId);

  const handleDelete = (file: Attachment) => {
    deleteFile(file.id);
  };

  return (
    <Upload
      handleDelete={handleDelete}
      attachmentList={data}
      uploadFile={uploadFile}
      isLoading={isLoading}
    />
  );
}
