import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";
import TogglingInput from "./TogglingInput";

type Props = {
  defaultValue: InstallationSchema;
};

export function ContactDetails({ defaultValue }: Props) {
  const { t } = useTranslation();

  const { customer, additionalInformation } = defaultValue;

  const {
    firstName,
    lastName,
    email,
    companyName,
    streetAndHouseNumber: contactStreetAndHouseNumber,
    cityAndZipCode,
    notes,
    telephoneNumber,
    street,
    houseNumber,
    city,
    zipCode,
  } = customer;

  return (
    <div className="flex-1">
      <h5 className="underline">{t("orders:customer")}:</h5>

      <TogglingInput
        name="additionalFirstName"
        label={t("orders:firstName")}
        originalValue={firstName}
        updatedValue={additionalInformation?.additionalFirstName}
      />

      <TogglingInput
        name="additionalLastName"
        label={t("orders:lastName")}
        originalValue={lastName}
        updatedValue={additionalInformation?.additionalLastName}
      />

      <TogglingInput
        name="additionalEmail"
        label={t("orders:email")}
        originalValue={email}
        updatedValue={additionalInformation?.additionalEmail}
      />

      <TogglingInput
        name="additionalCompanyName"
        label={t("orders:companyName")}
        originalValue={companyName}
        updatedValue={additionalInformation?.additionalCompanyName}
      />

      <TogglingInput
        name="additionalContactStreetAndHouseNumber"
        label={t("orders:streetAndHouseNumber")}
        originalValue={
          contactStreetAndHouseNumber || `${street} ${houseNumber}`
        }
        updatedValue={
          additionalInformation?.additionalContactStreetAndHouseNumber
        }
      />

      <TogglingInput
        name="additionalContactCityAndZipCode"
        label={t("orders:cityAndZipCode")}
        originalValue={cityAndZipCode || `${city} ${zipCode}`}
        updatedValue={additionalInformation?.additionalContactCityAndZipCode}
      />

      <TogglingInput
        name="additionalNotes"
        label={t("orders:notes")}
        originalValue={notes || "-"}
        updatedValue={additionalInformation?.additionalNotes}
      />

      <TogglingInput
        name="additionalTelephoneNumber"
        label={t("orders:telephoneNumber")}
        originalValue={telephoneNumber}
        updatedValue={additionalInformation?.additionalTelephoneNumber}
      />
    </div>
  );
}
