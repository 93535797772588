import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";
import { formattedDateTime } from "utils/global";

export default function Done({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();
  return (
    <div>
      <h5 className="underline">{t("general:done")}:</h5>
      <div className="flex gap-4">
        <div className="flex-1">
          <b>
            {t("orders:barrierDetails.setupDate")} / {t("orders:time")}:
          </b>
          <p className="m-0">
            {formattedDateTime(installation.setupDoneDateTime) || "-"}
          </p>
        </div>
        <div className="flex-1">
          <b>
            {t("orders:barrierDetails.pickupDate")} / {t("orders:time")}:
          </b>
          <p className="m-0">
            {formattedDateTime(installation.pickupDoneDateTime) || "-"}
          </p>
        </div>
      </div>
    </div>
  );
}
