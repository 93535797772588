import React from "react";
import { Form, Input } from "antd";

interface Props {
	containerClassName?: string;
	label?: any;
	name: string;
	initialValue?: any;
	rules?: any;
	input?: any;
	disabled?: boolean
}

const FormTextBox = React.memo((props: Props) => {
	const {
		containerClassName,
		label,
		name,
		initialValue,
		rules,
		input,
		disabled,
		...rest
	} = props;

	return (
		<div className={containerClassName ?? "form-group mb-2"}>
			<Form.Item
				initialValue={initialValue}
				name={name}
				label={label}
				rules={rules}
				{...rest}
			>
				{input.type === "password" ? (
					<Input.Password {...input} disabled={disabled}/>
				) : input.type === "textarea" ? (
					<Input.TextArea {...input} disabled={disabled}/>
				) : (
					<Input {...input} disabled={disabled}/>
				)}
			</Form.Item>
		</div>
	);
});

export { FormTextBox };