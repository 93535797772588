import { message } from "antd";
import i18n from "i18n";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AuthorizedService } from "shared/axios";
import { Customizable } from "types/customizable";

const { t } = i18n;

const endPoint = "/customizable";
export const CUSTOMIZABLE_LIST_QUERY_KEY = "customizable";

export const useCustomizableList = () => {
  return useQuery<Customizable[]>({
    queryKey: [CUSTOMIZABLE_LIST_QUERY_KEY],
    queryFn: () =>
      new AuthorizedService().get(endPoint).then((res: any) => res.data),
    refetchOnWindowFocus: false,
  });
};

export const useCustomizableUpdate = (customizableId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { key: string; value: string | null }) =>
      new AuthorizedService()
        .put(`${endPoint}/${customizableId}`, data)
        .then((res: any) => res),
    {
      onSuccess: () => {
        message.success(t("general:updatedSuccess"));
      },
      onError: () => {
        message.error(t("general:updateFail"));
      },
      onSettled: () => {
        queryClient.invalidateQueries(CUSTOMIZABLE_LIST_QUERY_KEY);
      },
    }
  );
};
