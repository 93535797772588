import { Button, Image, Tooltip } from "antd";
import { useInstallationInvoiceStatusUpdate } from "queryHook/installation";
import { useTranslation } from "react-i18next";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { PiMoney } from "react-icons/pi";
import { InstallationSchema } from "types/installation";
import { CLIENT_ORDER_EMAIL_ADDRESS } from "utils/global/constants";

export default function InvoiceStatusToggle({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();

  const { mutate, isLoading: invoiceStatusLoading } =
    useInstallationInvoiceStatusUpdate(installation.id);

  const handleInvoiceUploaded = () => {
    mutate({
      changeInvoiceUploadedByClient: true,
    });
  };

  const handleInvoicePaid = () => {
    mutate({
      changeInvoicePaidByClient: true,
    });
  };

  const handleTaxBooking = () => {
    mutate({
      changeTaxBooking: true,
    });
  };

  const updatedEmail =
    installation.additionalInformation?.additionalEmail ||
    installation.customer.email;

  return (
    <div className="flex gap-2" style={{ fontSize: "1.6rem" }}>
      <Tooltip placement="bottom" title={t("orders:invoiceUploaded")}>
        <Button
          className="remove-style cursor-pointer"
          style={{ opacity: installation.invoiceUploadedByClient ? 1 : 0.2 }}
          onClick={handleInvoiceUploaded}
          disabled={invoiceStatusLoading}
        >
          {updatedEmail === CLIENT_ORDER_EMAIL_ADDRESS ? (
            "E"
          ) : (
            <LiaFileInvoiceSolid />
          )}
        </Button>
      </Tooltip>
      <Tooltip placement="bottom" title={t("orders:invoicePaid")}>
        <Button
          className="remove-style cursor-pointer"
          style={{ opacity: installation.invoicePaidByClient ? 1 : 0.2 }}
          onClick={handleInvoicePaid}
          disabled={invoiceStatusLoading}
        >
          <PiMoney />
        </Button>
      </Tooltip>
      <Tooltip placement="bottom" title={t("orders:taxBooking")}>
        <Button
          className="remove-style cursor-pointer"
          style={{
            opacity: installation.taxBooking ? 1 : 0.2,
            display: "flex",
            alignItems: "center",
          }}
          onClick={handleTaxBooking}
          disabled={invoiceStatusLoading}
        >
          <Image
            preview={false}
            src="/img/tax-booking.svg"
            alt="Tax Booking"
            style={{ width: 25 }}
          />
        </Button>
      </Tooltip>
    </div>
  );
}
