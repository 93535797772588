import React, { useState, createContext, useContext } from "react";

type Props = {
  children: React.ReactNode;
};

type ShowInputSchema = {
  showInput: boolean;
  isLoading: boolean;
  setShowInput: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const ShowInputContext = createContext<ShowInputSchema>({
  showInput: false,
  setShowInput: () => {},
  isLoading: false,
  setIsLoading: () => {},
});

export const useShowInputContext = () => useContext(ShowInputContext);

const ShowInputProvider: React.FC<Props> = ({ children }) => {
  const [showInput, setShowInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ShowInputContext.Provider
      value={{ showInput, setShowInput, isLoading, setIsLoading }}
    >
      {children}
    </ShowInputContext.Provider>
  );
};

export default ShowInputProvider;
