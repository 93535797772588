import TinyMceEditor from "components/reusable/TinyMceEditor";
import { useTranslation } from "react-i18next";

export default function EmailPreview({
  to,
  subject,
  emailBody,
}: {
  to: string;
  subject: string;
  emailBody: string;
}) {
  const { t } = useTranslation();
  return (
    <div>
      <h5>
        {t("email:subject")}: {subject}
      </h5>
      <div className="mb-4">
        <b>{t("email:to")}:</b>&nbsp;
        <span>&lt;{to}&gt;</span>
      </div>
      <div
        style={{
          border: "1px solid #d9d9d9",
          padding: "8px",
        }}
      >
        <TinyMceEditor
          tinymceScriptSrc={"/tinymce/tinymce.min.js"}
          disabled={true}
          inline={true}
          initialValue={emailBody}
        />
      </div>
    </div>
  );
}
