import { QuestionCircleOutlined } from "@ant-design/icons";
import { ProColumns } from "@ant-design/pro-table";
import { Button, Popconfirm } from "antd";
import AntdTablePro from "components/antdTablePro";
import { ParkedCar, useDeleteParkedCar } from "queryHook/parkedCar";
import React from "react";
import { useTranslation } from "react-i18next";
import { BiTrash } from "react-icons/bi";

export default function WorkerParkedCarList({
  installationId,
  parkedCars,
}: {
  installationId: string;
  parkedCars: ParkedCar[] | undefined;
}) {
  const { t } = useTranslation();

  const { mutate: deleteParkedCar } = useDeleteParkedCar(installationId);

  const handleDelete = (id: string) => {
    deleteParkedCar(id);
  };

  const columns: ProColumns[] = [
    {
      key: "licensePlate",
      title: t("parkedCar:licensePlate"),
      render: (_, row: ParkedCar) => {
        return (
          <div className="flex justify-between items-center">
            <div>{row?.licensePlate}</div>
          </div>
        );
      },
    },
    {
      key: "carBrand",
      title: t("parkedCar:carBrand"),
      render: (_, row: ParkedCar) => {
        return (
          <div className="flex justify-between items-center">
            <div>{row?.brand?.name}</div>
          </div>
        );
      },
    },
    {
      key: "carColor",
      title: t("parkedCar:carColor"),
      render: (_, row: ParkedCar) => {
        return (
          <div className="flex justify-between items-center">
            <div>{row?.carColor}</div>
          </div>
        );
      },
    },
    {
      key: "action",
      title: t("orders:action"),
      valueType: "option",
      render: (_, row: ParkedCar, index) => [
        <div key={index}>
          <Popconfirm
            placement="leftBottom"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            title={t("general:confirmCarDelete")}
            onConfirm={() => handleDelete(row?.id)}
            okText={t("orders:yes")}
            cancelText={t("orders:no")}
          >
            <Button type="primary" style={{ background: "red" }}>
              <BiTrash />
            </Button>
          </Popconfirm>
        </div>,
      ],
    },
  ];

  return (
    <React.Fragment>
      {parkedCars && parkedCars.length > 0 && (
        <AntdTablePro
          columns={columns}
          rowKey="id"
          dataSource={parkedCars}
          pagination={false}
          toolBarRender={false}
        />
      )}
    </React.Fragment>
  );
}
