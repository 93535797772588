import { Button, Form } from "antd";
import TinyMceEditor from "components/reusable/TinyMceEditor";
import { useCustomizableUpdate } from "queryHook/customizable";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiPencil, BiSave } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { Customizable } from "types/customizable";

export default function CustomerMailBodyForm({
  customizable,
}: {
  customizable: Customizable;
}) {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [emailBody, setEmailBody] = useState(customizable.value || "");

  const { mutate, isLoading } = useCustomizableUpdate(customizable.id);

  const onSubmit = () => {
    mutate(
      {
        key: customizable.key,
        value: emailBody || null,
      },
      {
        onSuccess: () => {
          setEditMode(false);
        },
      }
    );
  };

  const onChange = (email: string) => {
    setEmailBody(email);
  };

  return (
    <Form
      onFinish={onSubmit}
      layout="vertical"
      style={{ padding: "1rem", background: "white" }}
    >
      <Form.Item label={t("customizable:customerMailBody")}>
        {editMode ? (
          <div>
            <TinyMceEditor value={emailBody} onEditorChange={onChange} />
          </div>
        ) : (
          <TinyMceEditor
            disabled={true}
            inline={true}
            initialValue={emailBody || "-"}
          />
        )}
      </Form.Item>
      <div className="flex justify-end">
        {editMode ? (
          <div className="flex gap-2">
            <Button
              key="save"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              <BiSave />
            </Button>
            <Button
              key="cancel"
              type="default"
              className="btn-outline"
              onClick={() => setEditMode(false)}
            >
              <RxCross2 />
            </Button>
          </div>
        ) : (
          <Button
            key="edit"
            type="primary"
            htmlType="button"
            onClick={() => setEditMode(true)}
          >
            <BiPencil />
          </Button>
        )}
      </div>
    </Form>
  );
}
