import { Button, Modal, Tabs } from "antd";
import { MouseEventHandler, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineFilePdf } from "react-icons/ai";
import { useReactToPrint } from "react-to-print";
import { InstallationSchema } from "types/installation";
import { getBarrierDetailsCity } from "utils/global/installationHelper";
import OrderPDF from "./orderModal/OrderPDF";
import FilesTab from "./orderModal/filesTab/FilesTab";
import OverviewTab from "./orderModal/overviewTab/OverviewTab";
import PaymentTab from "./orderModal/paymentTab/PaymentTab";
import VehiclesTab from "./orderModal/vehiclesTab/VehiclesTab";
import WorkerTab from "./orderModal/workerTab/WorkerTab";

interface ViewComponentProps {
  visible: boolean;
  onHide: MouseEventHandler<HTMLElement>;
  modalText: string;
  defaultValue: InstallationSchema;
}

const ViewComponent = ({
  visible,
  onHide,
  modalText,
  defaultValue,
}: ViewComponentProps) => {
  const { t } = useTranslation();
  const printRef = useRef<HTMLDivElement>(null);

  const {
    timeFrom,
    timeTill,
    additionalInformation,
    updatedDateRangeFrom,
    updatedDateRangeTo,
  } = defaultValue;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: modalText,
    pageStyle: `
      @page {
        size: landscape;
      }
    `,
  });

  // const { mutateAsync: uploadFile } = useAdminFileUpload(id);

  // const sendPdfToBackend = async (pdfBlob) => {
  //   const formData = new FormData();
  //   formData.append("file", pdfBlob, "vehicle_log.pdf");
  //   uploadFile(formData);
  // };

  return (
    <Modal
      visible={visible}
      title={
        <div className="installation-modal-header">
          <div>
            <div>{modalText}</div>
            <Button
              type="primary"
              className="modal-button"
              onClick={handlePrint}
            >
              <AiOutlineFilePdf />
            </Button>
          </div>
        </div>
      }
      onCancel={onHide}
      maskClosable={false}
      width={600}
      bodyStyle={{ height: 1000, overflow: "hidden", padding: "8px" }}
      footer={[
        <div key={1}>
          <Button key="back" onClick={onHide}>
            {t("main:close")}
          </Button>
        </div>,
      ]}
    >
      <Tabs
        defaultActiveKey="1"
        style={{ maxHeight: "100%", paddingInline: "8px" }}
        className="order-modal-tab"
      >
        <Tabs.TabPane tab={t("orders:modalTabs:overview")} key="overview">
          <OverviewTab installation={defaultValue} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("orders:modalTabs:payment")} key="payment">
          <PaymentTab installation={defaultValue} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("orders:modalTabs:worker")} key="worker">
          <WorkerTab installation={defaultValue} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("orders:modalTabs:files")} key="files">
          <FilesTab installation={defaultValue} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("orders:modalTabs:vehicles")} key="vehicles">
          <VehiclesTab installation={defaultValue} />
        </Tabs.TabPane>
      </Tabs>

      <OrderPDF
        ref={printRef}
        dateRangeFrom={updatedDateRangeFrom}
        dateRangeTo={updatedDateRangeTo}
        timeFrom={additionalInformation?.additionalTimeFrom ?? timeFrom}
        timeTill={additionalInformation?.additionalTimeTill ?? timeTill}
        address={getBarrierDetailsCity(defaultValue)}
        fileName={modalText}
      />
    </Modal>
  );
};

export default ViewComponent;
