import { useInstallationWorkerInformationUpdate } from "queryHook/installation";
import { InstallationSchema } from "types/installation";
import Notice from "../Notice";

export default function WorkerNotice({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { mutate, isLoading } = useInstallationWorkerInformationUpdate(
    installation.id
  );

  const onSubmit = (data: string | null) => {
    mutate({ workerNotice: data });
  };

  return (
    <Notice
      name="workerNotice"
      onSubmit={onSubmit}
      isLoading={isLoading}
      defaultValue={installation.workerNotice}
    />
  );
}
