import { Editor } from "@tinymce/tinymce-react";
import React from "react";

type TinyMceEditorProp = React.ComponentProps<typeof Editor>;

export default function TinyMceEditor(props: TinyMceEditorProp) {
  return (
    <Editor
      tinymceScriptSrc="/tinymce/tinymce.min.js"
      plugins="wordcount"
      init={{
        icons: "material",
        plugins: "link",
        toolbar:
          "undo redo fontsize fontfamily styles | bold italic underline forecolor backcolor link align",
        font_size_formats: "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
        toolbar_location: "bottom",
        height: 400,
        menubar: "false",
        textcolor_rows: "4",
        branding: false,
        link_default_target: "_blank",
        help_accessibility: false,
        language_url: "/tinymce/langs/de.js",
        language: "de",
      }}
      {...props}
    />
  );
}
