import React from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutateAsyncFunction,
  QueryObserverResult,
  RefetchOptions,
} from "react-query";
import { message, Spin } from "antd";
import jwt_decode from "jwt-decode";
import { authToken } from "utils/global";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

export interface AuthProviderConfig<User = unknown> {
  key?: string;
  loadUser: (data: any) => Promise<User>;
  loginFn: (data: any) => Promise<User>;
  logoutFn: () => Promise<any>;
  waitInitial?: boolean;
  LoaderComponent?: () => any; //JSX.Element;
  ErrorComponent?: (error: any) => any; //JSX.Element;
}

export interface AuthContextValue<
  User = unknown,
  Error = unknown,
  LoginCredentials = unknown
> {
  user: User | undefined;
  login: UseMutateAsyncFunction<User, any, LoginCredentials>;
  logout: UseMutateAsyncFunction<any, any, void, any>;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  refetchUser: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<User, Error>>;
  error: Error | null;
}

export interface AuthProviderProps {
  children: React.ReactNode;
}

export function initReactQueryAuth<
  User = unknown,
  Error = unknown,
  LoginCredentials = unknown
>(config: AuthProviderConfig<User>) {
  const AuthContext = React.createContext<AuthContextValue<
    User,
    Error,
    LoginCredentials
  > | null>(null);
  AuthContext.displayName = "AuthContext";

  const {
    loadUser,
    loginFn,
    logoutFn,
    key = "auth-user",
    waitInitial = true,
    LoaderComponent = () => <Spin />,
    ErrorComponent = (error: any) => (
      <div style={{ color: "tomato" }}>{JSON.stringify(error)}</div>
    ),
  } = config;

  function AuthProvider({ children }: AuthProviderProps): any {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    const {
      data: user,
      error,
      status,
      isLoading,
      isIdle,
      isSuccess,
      refetch,
    } = useQuery<User, Error>({
      queryKey: key,
      queryFn: loadUser,
      retry: false,
    });

    const setToken = React.useCallback(
      (data: User) => queryClient.setQueryData(key, data),
      [queryClient]
    );

    const loginMutation = useMutation({
      mutationFn: loginFn,
      onSuccess: (response: any) => {
        if (!isEmpty(response?.data)) {
          authToken.setToken(response?.data);
          setToken(jwt_decode(response?.data));
          message.success(t("warnings:successfulLogin"));
        }
      },
      onError: () => {},
    });

    const logoutMutation = useMutation({
      mutationFn: logoutFn,
      onSuccess: () => {
        queryClient.removeQueries();
      },
    });

    const value = React.useMemo(
      () => ({
        user,
        error: loginMutation.error as Error,
        refetchUser: refetch,
        login: loginMutation.mutateAsync,
        isLoggedIn: user ? true : false,
        isLoggingIn: loginMutation.isLoading,
        logout: logoutMutation.mutateAsync,
        isLoggingOut: logoutMutation.isLoading,
      }),
      [
        user,
        error,
        refetch,
        loginMutation.mutateAsync,
        loginMutation.isLoading,
        logoutMutation.mutateAsync,
        logoutMutation.isLoading,
      ]
    );

    if (isSuccess || !waitInitial) {
      return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      );
    }

    if (isLoading || isIdle) {
      return <LoaderComponent />;
    }

    if (error) {
      return <ErrorComponent error={error} />;
    }

    return <div>Unhandled status: {status}</div>;
  }

  function useAuth() {
    const context = React.useContext(AuthContext);
    if (!context) {
      throw new Error(`useAuth must be used within an AuthProvider`);
    }
    return context;
  }

  return { AuthProvider, AuthConsumer: AuthContext.Consumer, useAuth };
}
