import * as React from "react";
import { ConfigProvider, Input } from "antd";
import "@ant-design/pro-table/dist/table.css";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { ColumnsStateType } from "@ant-design/pro-table/lib/typing";
import enUS from "antd/lib/locale/en_US";

interface TableProps {
  columns: ProColumns[];
  columnsState?: ColumnsStateType;
  className?: string;
}
const AntdTablePro = React.memo(
  ({
    columns,
    request,
    columnsState,
    inputElement,
    toolBarRender = [],
    rowClassName,
    className,
    ...rest
  }: TableProps & any) => {
    const [activeRow, setActiveRow] = React.useState<string | null>(null);

    const handleRowClick = (record: any) => {
      setActiveRow(record.id);
    };

    const getRowClassName = (record: any) => {
      const baseClassName = rowClassName
        ? typeof rowClassName === "function"
          ? rowClassName(record)
          : rowClassName
        : "";

      return record.id === activeRow
        ? `${baseClassName} row-active`
        : baseClassName;
    };
    return (
      <ConfigProvider locale={enUS}>
        <ProTable
          className={`App ${className ? className : ""}`}
          size="small"
          columns={columns}
          request={request}
          rowClassName={getRowClassName}
          onRow={(dataSource) => ({
            onClick: () => handleRowClick(dataSource),
          })}
          toolBarRender={
            toolBarRender
              ? () => [
                  <Input.Search
                    key={Math.random()}
                    style={{
                      width: 200,
                    }}
                    enterButton
                    allowClear
                    onSearch={(value) => inputElement?.handleSearch(value)}
                    onChange={(value) => inputElement?.handleChange(value)}
                    placeholder={inputElement.placeholder}
                  />,
                  ...toolBarRender,
                ]
              : false
          }
          search={false}
          columnsState={columnsState}
          {...rest}
        />
      </ConfigProvider>
    );
  }
);

export default AntdTablePro;
