import { useQuery } from "react-query";
import { AuthorizedService } from "shared/axios";

const END_POINT = "/brand";
const BRAND_LIST_QUERY_KEY = "brand-list";

export type Brand = {
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
};

export const useGetBrandList = () => {
  return useQuery<Brand[]>({
    queryKey: [BRAND_LIST_QUERY_KEY],
    queryFn: async () => {
      const res = await new AuthorizedService().get(END_POINT);
      return res.data;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
