import OrderTable from "components/orders/OrderTable";
import { InstallationSchema } from "types/installation";
import { isAdmin } from "utils/global";

function Index() {
  const filter = {
    rejectOrder: true,
  };
  return (
    <OrderTable
      pageSize={100}
      order="DESC"
      orderBy="createdAt"
      rowClassName={(record: InstallationSchema) => {
        if (!isAdmin()) return "";
        if (record.closeOrder) return "";
        if (record.rejectOrder) return "installation-bg-gray";
        if (!record.acceptOrder) return "installation-bg-green";
        return "installation-bg-yellow";
      }}
      statusFilter={filter}
    />
  );
}

export default Index;
