import { ProColumns } from "@ant-design/pro-table";
import { Spin } from "antd";
import { useGetCityListPagination } from "queryHook/city";
import { debounce } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AntdTablePro from "components/antdTablePro";
import CitiesPriceAmount from "./CitiesPriceAmount";
import { CityPriceType } from "types/installation";

type FilterParams = {
  page: number;
  size: number;
  search: string;
};

function index() {
  const [filterParams, setFilterParams] = useState<FilterParams>({
    page: 1,
    size: 100,
    search: "",
  });
  const { data, isLoading } = useGetCityListPagination(filterParams);

  const { t } = useTranslation();

  if (isLoading) return <Spin />;

  const columns: ProColumns[] = [
    {
      title: t("cities:cityName"),
      dataIndex: "name",
    },
    {
      title: t("cities:basePrice"),
      render: (_, row) => {
        return <CitiesPriceAmount row={row} name={CityPriceType.BASEPRICE} />;
      },
    },
    {
      title: t("cities:permissionPrice"),
      render: (_, row) => {
        return (
          <CitiesPriceAmount row={row} name={CityPriceType.PERMISSIONPRICE} />
        );
      },
    },
    {
      title: t("cities:withOutsideLiftPrice"),
      render: (_, row) => {
        return (
          <CitiesPriceAmount
            row={row}
            name={CityPriceType.WITHOUTSIDELIFTPRICE}
          />
        );
      },
    },
  ];

  const getSearchKeywordsDeb = debounce((value) => {
    setFilterParams({ ...filterParams, search: value });
  }, 500);

  return (
    <AntdTablePro
      columns={columns}
      rowKey="id"
      dataSource={data?.items}
      pagination={{
        showSizeChanger: true,
        showQuickJumper: true,
        pageSize: filterParams?.size,
        total: data?.count,
        onChange: (page, pageSize) => {
          setFilterParams({
            ...filterParams,
            page: page,
            size: pageSize,
          });
        },
      }}
      inputElement={{
        placeholder: t("main:search"),
        handleChange: (e) => getSearchKeywordsDeb(e.target.value),
        handleSearch: (value) =>
          setFilterParams({ ...filterParams, search: value }),
      }}
    />
  );
}

export default index;
