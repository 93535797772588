import { useGetGoogleCalendarEventColors } from "queryHook/temp";

export default function GoogleColors() {
  const { data, isLoading } = useGetGoogleCalendarEventColors();

  if (isLoading || !data) return null;

  console.log(data);
  return (
    <ul style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {Object.entries(data).map(([index, color]) => {
        console.log(index, color);
        return (
          <li
            key={index}
            style={{
              // @ts-ignore
              background: color.background,
              // @ts-ignore
              color: color.foreground,
            }}
          >
            44600 +H / 20.08. / 24007006 / Fr. Doe
          </li>
        );
      })}
    </ul>
  );
}
