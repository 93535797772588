import { FormInstance, message } from "antd";
import { RefObject } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AuthorizedService } from "shared/axios";
import { PaginatedResponse } from "types";
import { isAdmin } from "utils/global";

const END_POINT = "/worker";
const GET_WORKER_LIST_QUERY_KEY = "worker-list";

export type WorkerSchema = {
  createdAt: string;
  updatedAt: string;
  firstName: string;
  middleName: string | null;
  userName: string;
  lastName: string;
  email: string;
  avatar: string | null;
  role: "worker";
};

// No pagination
export const useGetAllWorkerList = () => {
  return useQuery<WorkerSchema[]>({
    queryKey: "all-workers",
    queryFn: async () => {
      const res = await new AuthorizedService().get(END_POINT, {
        size: "all",
      });
      return res.data;
    },
    enabled: isAdmin(),
  });
};

export const useGetWorkerList = (params) => {
  return useQuery<PaginatedResponse<WorkerSchema>>({
    queryKey: [GET_WORKER_LIST_QUERY_KEY, params],
    queryFn: async () => {
      const res = await new AuthorizedService().get(END_POINT, params);
      return res.data;
    },
    enabled: isAdmin(),
  });
};

export const usePostWorker = (formRef: RefObject<FormInstance>) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: any) => {
      await new AuthorizedService().post(END_POINT, data);
    },
    onSuccess: () => {
      message.success(t("general:createdSuccess"));
    },
    onError: (e: any) => {
      if (e?.status !== 400) {
        message.error(t("general:createFail"));
        return;
      }

      if (e?.data?.message?.email === "Email is taken!") {
        formRef.current?.setFields([
          {
            name: "email",
            errors: [t("warnings:emailTaken")],
          },
        ]);
      }

      if (e?.data?.message?.userName === "Username is taken!") {
        formRef.current?.setFields([
          {
            name: "userName",
            errors: [t("warnings:userNameTaken")],
          },
        ]);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([GET_WORKER_LIST_QUERY_KEY]);
    },
  });
};

export const useDeleteWorker = (userName: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await new AuthorizedService().delete(END_POINT, {
        userName,
      });
    },
    onSuccess: () => {
      message.success(t("general:deleteSuccess"));
    },
    onError: () => {
      message.error(t("general:deleteFail"));
    },
    onSettled: () => {
      queryClient.invalidateQueries([GET_WORKER_LIST_QUERY_KEY]);
    },
  });
};
