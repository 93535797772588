import { Button, Col, ColProps, Form, Input, Row, RowProps } from "antd";
import { useTranslation } from "react-i18next";

const colStyles: ColProps = {
  xs: 24,
  md: 12,
};

const rowStyles: RowProps = {
  gutter: 16,
};

export default function WorkerForm({ isLoading }: { isLoading: boolean }) {
  const { t } = useTranslation();

  const handleFocus = (event) => {
    event.target.removeAttribute("readonly");
  };

  return (
    <>
      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("general:firstName")}
            name="firstName"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...colStyles}>
          <Form.Item
            label={t("general:lastName")}
            name="lastName"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("general:userName")}
            name="userName"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
          >
            <Input autoComplete="off" readOnly onFocus={handleFocus} />
          </Form.Item>
        </Col>
        <Col {...colStyles}>
          <Form.Item
            label={t("login:email")}
            name="email"
            rules={[
              {
                type: "email",
                message: t("warnings:invalidValue"),
              },
            ]}
          >
            <Input autoComplete="off" readOnly onFocus={handleFocus} />
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("login:password")}
            name="password"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
          >
            <Input.Password autoComplete="off" readOnly onFocus={handleFocus} />
          </Form.Item>
        </Col>
      </Row>

      <Button type="primary" htmlType="submit" loading={isLoading}>
        {t("general:submit")}
      </Button>
    </>
  );
}
