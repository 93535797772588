import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { City, CityPriceType } from "types/installation";
import { CityPriceRequestBody, useUpdateCityPriceAmount } from "queryHook/city";

export default function CitiesPriceAmount({
  row,
  name,
}: {
  row: City;
  name: CityPriceType;
}) {
  const { t } = useTranslation();
  const { mutate } = useUpdateCityPriceAmount(row.id);

  const onBlur = (e) => {
    const newValue = e.target.valueAsNumber || null;
    if (newValue === row[name] || !newValue || newValue < 0) return;

    let updatedValues: CityPriceRequestBody;

    if (name === CityPriceType.PERMISSIONPRICE) {
      updatedValues = { permissionPrice: newValue };
    } else if (name === CityPriceType.BASEPRICE) {
      updatedValues = { basePrice: newValue };
    } else {
      updatedValues = { withOutsideLiftPrice: newValue };
    }

    mutate(updatedValues);
  };

  const positiveNumberValidator = (_rule, value) => {
    if (!value) return Promise.resolve();

    const num = Number(value);

    if (Number.isInteger(num) && num > 0) return Promise.resolve();
    return Promise.reject(t("warnings:invalidValue"));
  };

  const initialValue = row[name];

  return (
    <Form>
      <Form.Item
        labelAlign="left"
        className="m-0"
        name={name}
        initialValue={initialValue}
        rules={[
          {
            validator: positiveNumberValidator,
          },
        ]}
      >
        <Input style={{ width: 100 }} type="number" onBlur={onBlur} min={0} />
      </Form.Item>
    </Form>
  );
}
