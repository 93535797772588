import { Divider, Form, Spin } from "antd";
import {
  useDeleteAuthorityFile,
  useGetAuthorityById,
  useUpdateAuthority,
  useUploadAuthorityFiles,
} from "queryHook/authority";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiChevronLeft } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import AuthorityForm from "../AuthorityForm";

export default function EditAuthority() {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();

  const [files, setFiles] = useState<File[]>([]);

  const { data: authorityDetails, isLoading: authorityDetailsLoading } =
    useGetAuthorityById(params.id);

  const { mutate, isLoading } = useUpdateAuthority(params.id);

  const { handleUpload, uploadLoading } = useUploadAuthorityFiles();

  const { mutateAsync: deleteUploadedFile } = useDeleteAuthorityFile(params.id);

  const onSubmit = async (data) => {
    await handleUpload(params.id, files);
    mutate(data);
  };

  const handleDeleteUploadedFile = async (id: string) => {
    deleteUploadedFile(id);
  };

  if (authorityDetailsLoading) {
    return <Spin />;
  }

  if (!authorityDetails) {
    return <div>{t("warnings:error")}</div>;
  }

  return (
    <Form
      layout="vertical"
      style={{ padding: "1rem", background: "white" }}
      onFinish={onSubmit}
    >
      <h5>
        <Link to="/authority/all" style={{ color: "rgba(0, 0, 0, 0.85)" }}>
          <BiChevronLeft />
          {t("authority:editAuthority")}
        </Link>
      </h5>
      <Divider orientation="left" style={{ marginTop: 0 }} />

      <AuthorityForm
        isLoading={isLoading || uploadLoading}
        setFiles={setFiles}
        authorityDetails={authorityDetails}
        deleteUploadedFile={handleDeleteUploadedFile}
      />
    </Form>
  );
}
