import AdminUpload from "components/orders/AdminUpload";
import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";

export default function FilesTab({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <h5 className="underline">{t("orders:modalTabs:files")}</h5>
      </div>
      <div>
        <AdminUpload installation={installation} />
      </div>
    </div>
  );
}
