import { authToken } from "utils/global";
import { Ajax } from "./Ajax";

export const apiUrls = "http://127.0.0.1:5000/api/v1";
export const apiUrl = process.env.REACT_APP_API_BASE_URL;
class AuthorizedService extends Ajax {
	public constructor() {
		const token: any = authToken?.getToken();
		super({
			headerAuthorization: () => {
				if (token) return token;
			},
			baseURL: apiUrl,
		});
	}
}

class AxiosService extends Ajax {
	public constructor() {
		super({
			baseURL: apiUrl,
		});
	}
}

export { AxiosService, AuthorizedService };
