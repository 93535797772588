import { forwardRef } from "react";
import { formattedDate, formattedTime } from "utils/global";

type Props = {
  dateRangeFrom: string;
  dateRangeTo: string;
  timeFrom: string;
  timeTill: string;
  address: string;
  fileName: string;
};

const OrderPDF = forwardRef<HTMLDivElement, Props>(function OrderPDF(
  props,
  ref
) {
  const { dateRangeFrom, dateRangeTo, timeFrom, timeTill, address, fileName } =
    props;

  return (
    <div style={{ display: "none" }}>
      <div ref={ref} style={{ textAlign: "center" }}>
        <div style={{ fontSize: "9rem", fontWeight: 800, lineHeight: 1.3 }}>
          {dateRangeFrom === dateRangeTo ? (
            <div>
              <div style={{ fontWeight: 500 }}>am</div>
              <div style={{ fontSize: "10rem" }}>
                {formattedDate(dateRangeFrom)}
              </div>
            </div>
          ) : (
            <div>
              <div>
                <span style={{ fontWeight: 500 }}>ab</span>&nbsp;
                {formattedDate(dateRangeFrom)}
              </div>
              <div>
                <span style={{ fontWeight: 500 }}>bis</span>&nbsp;
                {formattedDate(dateRangeTo)}
              </div>
            </div>
          )}
          <div>
            {formattedTime(timeFrom)} - {formattedTime(timeTill)} h
          </div>
        </div>
        <div style={{ fontSize: "12px" }}>{address}</div>
        <div style={{ position: "fixed", right: 0, bottom: 0 }}>{fileName}</div>
      </div>
    </div>
  );
});

export default OrderPDF;
