import { Image, Dropdown, Menu } from "antd";
import { useAuth } from "queryHook/auth";
import { useHistory } from "react-router";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { AiOutlineCaretUp } from "react-icons/ai";
import { QueryCache } from "react-query";
import { useTranslation } from "react-i18next";
import { isWorker } from "utils/global";

const TopMenu = ({ onlyIcons, toggleOnlyIcons }: any) => {
  const { user, logout } = useAuth();
  const queryCache = new QueryCache();
  const history = useHistory();
  const { t } = useTranslation();
  const menu = (
    <>
      <AiOutlineCaretUp />
      <Menu className="user-dropdown">
        {/* <Menu.Item key="1">My Profile</Menu.Item>
			<Menu.Item key="2">Inbox</Menu.Item> */}
        <Menu.Item
          key="3"
          onClick={() => {
            logout();
            queryCache.clear();
            history.push("/");
          }}
        >
          {t("main:logout")}
        </Menu.Item>
      </Menu>
    </>
  );
  return (
    <div className="top-nav">
      <div className="left-item d-flex align-items-center">
        {/* toggle class logo-toggle in logo while clicking togglebtn */}
        <div className={`logo ${onlyIcons ? "logo-toggle" : ""}`}>
          <Image
            preview={false}
            src="/img/halteverbot24.png"
            style={{ height: 40 }}
          />
        </div>
        <div className="d-flex align-items-center">
          <button className="togglebtn" onClick={toggleOnlyIcons}>
            <HiOutlineMenuAlt3 />
          </button>
        </div>
      </div>
      <div className="user">
        {/* <ul>
					<li>
						<a href="">
							<BiEnvelope />
						</a>
					</li>
					<li>
						<a href="">
							<IoMdNotificationsOutline />
						</a>
					</li>
				</ul> */}
        <Dropdown overlay={menu} trigger={["click"]}>
          <div className="site-dropdown-context-menu d-flex">
            <div className="user-avatar">
              <Image
                preview={false}
                src={user?.image ?? "/img/user-1.png"}
                style={{ height: 40 }}
                onError={(e: any) => (
                  (e.target.onerror = null), (e.target.src = "/img/user-1.png")
                )}
              />
            </div>
            <div>
              <span>{t("main:welcome")},</span>
              <h6>
                {user?.firstName} {isWorker() && user?.lastName}
              </h6>
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default TopMenu;
