import { Button, Form, FormInstance } from "antd";
import UploadedListItem from "components/reusable/UploadListItem";
import { useShowInputContext } from "context/ShowInputContext";
import StatusSelect from "pages/orders/StatusSelect";
import { useInstallationAdditionalInformationUpdate } from "queryHook/installation";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BiPencil, BiSave } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import {
  AdditionalInstallationInformation,
  BarrierDetailsSchema,
  InstallationSchema,
  LicensePlates,
  OrderModelForm,
  UpdatedPrice,
} from "types/installation";
import {
  cleanData,
  formatDateYYYYMMDD,
  getRelativeTime,
  isSunday,
} from "utils/global";
import {
  CITY_MANNHEIM,
  DEFAULT_PICKUP_DATE_DIFFERENCE,
  DEFAULT_SETUP_DATE_DIFFERENCE,
  SETUP_DATE_DIFFERENCE_FOR_MANNHEIM,
} from "utils/global/constants";
import BarrierDetails from "../BarrierDetails";
import { ContactDetails } from "../ContactDetails";
import CustomerLoginLog from "../CustomerLoginLog";
import { InstallationDetails } from "../InstallationDetails";
import PriceTable from "../PriceTable";
import AdminNotice from "./AdminNotice";

export default function OverviewTab({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();

  const [form] = Form.useForm<OrderModelForm>();
  const formRef = useRef<FormInstance<OrderModelForm>>(null);

  const { id, dateRangeFrom, dateRangeTo } = installation;

  const { showInput, setShowInput, setIsLoading } = useShowInputContext();

  const { mutateAsync, isLoading } =
    useInstallationAdditionalInformationUpdate(id);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    form.setFieldsValue({
      additionalCity: installation.additionalInformation?.additionalCity,
    });
  }, [installation.additionalInformation?.additionalCity]);

  const handleShowInputs = () => setShowInput(true);

  const onSubmit = async (data: OrderModelForm) => {
    const isAdditionalDateFromUpdated =
      (installation.updatedDateRangeFrom || "") !==
      (data.updatedDateRangeFrom || "");

    let isCityUpdated = false;
    if (data.additionalCity !== undefined) {
      isCityUpdated =
        data.additionalCity !==
        (installation.additionalInformation?.additionalCity ??
          installation.city.name);
    }

    let setupDate = data.barrierDetailsSetupDate;

    const updatedDateRangeFrom = data.updatedDateRangeFrom || dateRangeFrom;
    const updatedDateRangeTo = data.updatedDateRangeTo || dateRangeTo;

    // Update barrier setup date to 5 days before the dateFrom
    // Whenever user updates dateFrom or when barrierDetailsSetupDate is empty
    if (
      isAdditionalDateFromUpdated ||
      data.barrierDetailsSetupDate.trim() === "" ||
      isCityUpdated
    ) {
      setupDate = formatDateYYYYMMDD(
        getRelativeTime(updatedDateRangeFrom, DEFAULT_SETUP_DATE_DIFFERENCE)
      );

      // If city is Mannheim, setup date is 4 days before
      if ((data.additionalCity || installation.city.name) === CITY_MANNHEIM) {
        setupDate = formatDateYYYYMMDD(
          getRelativeTime(
            updatedDateRangeFrom,
            SETUP_DATE_DIFFERENCE_FOR_MANNHEIM
          )
        );
      }

      // If setupDate is on Sunday, move it to next day
      if (isSunday(setupDate)) {
        setupDate = formatDateYYYYMMDD(getRelativeTime(setupDate, 1));
      }
    }

    const isAdditionalDateToUpdated =
      (installation.updatedDateRangeTo || "") !==
      (data.updatedDateRangeTo || "");

    let pickupDate = data.barrierDetailsPickupDate;

    // Update barrier pickup date to 1 days after the dateTo
    // Whenever user updates dateTo or barrierDetailsPickupDate is empty
    if (
      isAdditionalDateToUpdated ||
      data.barrierDetailsPickupDate.trim() === ""
    ) {
      pickupDate = formatDateYYYYMMDD(
        getRelativeTime(updatedDateRangeTo, DEFAULT_PICKUP_DATE_DIFFERENCE)
      );

      // If pickupDate is on Sunday, move it to next day
      if (isSunday(pickupDate)) {
        pickupDate = formatDateYYYYMMDD(getRelativeTime(pickupDate, 1));
      }
    }

    const additionalInformation: AdditionalInstallationInformation = {
      // installation details
      additionalPurpose: data.additionalPurpose,
      additionalStatus: data.additionalStatus,
      additionalTimeFrom: data.additionalTimeFrom,
      additionalTimeTill: data.additionalTimeTill,
      additionalLengthOfBoard: data.additionalLengthOfBoard,
      additionalStreetAndHouseNumber: data.additionalStreetAndHouseNumber,
      additionalZipCode: data.additionalZipCode,
      additionalCity: data.additionalCity,
      additionalWithOutsideLift: data.additionalWithOutsideLift,
      additionalIsTwoSiteBlock: data.additionalIsTwoSiteBlock,
      additionalIsWithPermission: data.additionalIsWithPermission,

      // contact details
      additionalFirstName: data.additionalFirstName,
      additionalLastName: data.additionalLastName,
      additionalEmail: data.additionalEmail,
      additionalCompanyName: data.additionalCompanyName,
      additionalContactStreetAndHouseNumber:
        data.additionalContactStreetAndHouseNumber,
      additionalContactCityAndZipCode: data.additionalContactCityAndZipCode,
      additionalNotes: data.additionalNotes,
      additionalTelephoneNumber: data.additionalTelephoneNumber,
      commentHVZ: data.commentHVZ,
    };

    const updatedBarrierDetails: BarrierDetailsSchema = {
      halteverbot24: data.barrierDetailsHalteverbot24,
      city: data.barrierDetailsCity,
    };

    const licensePlates: LicensePlates = {
      licensePlate1: data.licensePlate1,
      licensePlate2: data.licensePlate2,
      licensePlate3: data.licensePlate3,
      licensePlate4: data.licensePlate4,
      licensePlate5: data.licensePlate5,
      licensePlate6: data.licensePlate6,
      licensePlate7: data.licensePlate7,
      licensePlate8: data.licensePlate8,
      licensePlate9: data.licensePlate9,
      licensePlate10: data.licensePlate10,
    };

    const updatedPrice: UpdatedPrice = {
      totalPrice: Number(data.totalPrice),
    };

    const cleanedAdditionalInformation = cleanData(additionalInformation);
    const cleanedLicensePlates = cleanData(licensePlates);

    await mutateAsync({
      additionalInformation: cleanedAdditionalInformation,
      licensePlates: cleanedLicensePlates,
      ...(data.totalPrice && { updatedPrice }),
      barrierDetails: updatedBarrierDetails,
      setupDate,
      setupTime: installation.setupTime, // Send default value because there is no input field
      pickupDate,
      updatedDateRangeFrom,
      updatedDateRangeTo,
    });

    setShowInput(false);
  };

  const handleSaveInputs = () => {
    formRef.current?.submit();
  };

  return (
    <div>
      <div className="flex justify-between items-center gap-2">
        <h5 className="underline">{t("orders:modalTabs:overview")}</h5>
        {showInput ? (
          <div className="flex gap-2">
            <Button
              key={"save"}
              type="primary"
              onClick={handleSaveInputs}
              loading={isLoading}
            >
              <BiSave />
            </Button>
            <Button
              key={"cancel"}
              type="default"
              className="btn-outline"
              onClick={() => setShowInput(false)}
            >
              <RxCross2 />
            </Button>
          </div>
        ) : (
          <Button key={"edit"} type="primary" onClick={handleShowInputs}>
            <BiPencil />
          </Button>
        )}
      </div>
      <div>
        <Form
          form={form}
          validateTrigger="onChange"
          ref={formRef}
          onFinish={onSubmit}
          autoComplete="off"
          className="flex flex-col gap-4 mb-4"
        >
          <div className="flex gap-4">
            <InstallationDetails installation={installation} />
            <ContactDetails defaultValue={installation} />
          </div>

          <div>
            <h5 className="underline">{t("general:documents")}:</h5>
            <div className="responsive-two-column">
              {installation.publicAttachments?.length === 0
                ? "-"
                : installation.publicAttachments?.map((attachment) => (
                    <UploadedListItem file={attachment} key={attachment.id} />
                  ))}
            </div>
          </div>

          <BarrierDetails installation={installation} form={form} />

          {installation.priceTable ? (
            <div>
              <h5 className="underline">
                {t("orders:priceTable.priceTable")}:
              </h5>
              <PriceTable
                priceTable={installation.priceTable}
                updatedPrice={installation.updatedPrice}
              />
            </div>
          ) : null}

          <div>
            <h5 className="underline">{t("orders:status")}:</h5>
            <StatusSelect row={installation} />
          </div>
        </Form>
        <AdminNotice installation={installation} />
        <div className="flex flex-col gap-4 mt-4">
          <CustomerLoginLog installationId={id} />
        </div>
      </div>
    </div>
  );
}
