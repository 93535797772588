import { message } from "antd";
import {
  Accept,
  ErrorCode,
  FileError,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import { useTranslation } from "react-i18next";
import { AiFillFileAdd } from "react-icons/ai";
import { Spin } from "antd";
import { LoadingSpinner } from "components/loader";
import {
  DEFAULT_ACCEPTED_FILE_TYPE,
  MAX_NO_OF_FILES_AT_ONCE,
  MAX_UPLOAD_FILE_SIZE,
} from "utils/global/constants";

interface Props {
  uploadFn: Function;
  maxSize?: number;
  maxFiles?: number;
  acceptedFileType?: Accept;
  isLoading?: boolean;
}

/**
 * Instant file uploader
 */
export default function FileUploader({
  acceptedFileType = DEFAULT_ACCEPTED_FILE_TYPE,
  uploadFn,
  maxSize = MAX_UPLOAD_FILE_SIZE,
  maxFiles = MAX_NO_OF_FILES_AT_ONCE,
  isLoading,
}: Props) {
  const { t } = useTranslation();

  const fileSizeValidator = (file: File): FileError | FileError[] | null => {
    const fileSize = file.size / 1024 / 1024; // size in MB
    if (fileSize > maxSize) {
      return {
        code: ErrorCode.FileTooLarge,
        message: t("upload:error:fileTooLarge", { fileName: file.name }),
      };
    }
    return null;
  };

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const hasManyFilesError = fileRejections.some((e) => {
      return e.errors.some((error) => {
        return error.code === ErrorCode.TooManyFiles;
      });
    });

    if (hasManyFilesError) {
      message.error(t("upload:error:tooManyFiles"));
      return;
    }

    if (fileRejections.length > 0) {
      fileRejections.map((e) => {
        e.errors.forEach((error) => {
          if (error.code === ErrorCode.FileInvalidType) {
            message.error(t("upload:error:invalidFileType"));
          } else {
            message.error(error.message);
          }
        });
      });
      return;
    }

    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append("file", file);
    });

    uploadFn(formData);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileType,
    validator: fileSizeValidator,
    onDrop,
    maxFiles,
  });

  return (
    <div>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div>{t("upload:dragDropMsg", { maxSize })}</div>
        <div style={{ fontSize: "3rem", lineHeight: "1" }}>
          {isLoading ? (
            <Spin indicator={<LoadingSpinner />} className="spinner" />
          ) : (
            <AiFillFileAdd />
          )}
        </div>
      </div>
    </div>
  );
}
