import { Form, Button, Col, Row, Image } from "antd";
import { FormTextBox } from "components/forms";
import { useAuth } from "queryHook/auth";
import { useEffect } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { isWorker } from "utils/global";

const Index = () => {
  const { login, isLoggedIn, isLoggingIn } = useAuth();
  const { t } = useTranslation();

  const onFinish = (values: any) => {
    login(values);
  };

  const onFinishFailed = () => {};

  useEffect(() => {
    if (isLoggedIn) {
      setTimeout(() => {
        window.location.href = isWorker() ? "/worker-order/all" : "/dashboard";
      }, 100);
    }
  }, [isLoggedIn]);

  return (
    <Row className="login-wrap">
      <Col lg={12} className="login-bg">
        <div className="logo">
          <Image preview={false} src="/img/halteverbot24.png" />
        </div>
        <Image preview={false} src="/img/login-cover.png" />
      </Col>
      <Col lg={12} className="login-form">
        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <FormTextBox
            label={`${t("general:userName")} / ${t("login:email")}`}
            name="email"
            rules={[{ required: true, message: t("warnings:required") }]}
            input={{
              className: "form-control",
            }}
          />

          <FormTextBox
            label={t("login:password")}
            name="password"
            rules={[{ required: true, message: t("warnings:required") }]}
            input={{
              type: "password",
              className: "form-control",
              iconRender: (visible: any) =>
                visible ? <AiOutlineEyeInvisible /> : <AiOutlineEye />,
            }}
          />
          <div style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" disabled={isLoggingIn}>
              {isLoggingIn ? t("login:submitting") : t("login:submit")}
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default Index;
