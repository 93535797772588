import { Button, Modal } from "antd";
import UploadedListItem from "components/reusable/UploadListItem";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Authority } from "types/authority";

type AuthorityModalProps = {
  visible: boolean;
  onHide: MouseEventHandler<HTMLElement>;
  modalText: string;
  defaultValue: Authority;
};

export default function AuthorityModal({
  visible,
  onHide,
  modalText,
  defaultValue,
}: AuthorityModalProps) {
  const { t } = useTranslation();

  const {
    address,
    applicationForm,
    contactPerson,
    email,
    fax,
    name,
    hints,
    phone,
    sendInstallationProtocolToOffice,
    attachments,
  } = defaultValue;

  return (
    <Modal
      visible={visible}
      title={<div>{modalText}</div>}
      onCancel={onHide}
      maskClosable={false}
      width={600}
      footer={[
        <div key={Math.random()}>
          <Button key="back" onClick={onHide}>
            {t("main:close")}
          </Button>
        </div>,
      ]}
    >
      <div>
        <div className="grid grid-cols-2">
          <div>
            <b>{t("authority:field.name")}: </b>
            <p>{name}</p>
          </div>
          <div>
            <b>{t("authority:field.address")}: </b>
            <p>{address}</p>
          </div>
          <div>
            <b>{t("authority:field.contactPerson")}: </b>
            <p>{contactPerson}</p>
          </div>
          <div>
            <b>{t("authority:field.email")}: </b>
            <p>{email}</p>
          </div>
          <div>
            <b>{t("authority:field.phone")}: </b>
            <p>{phone}</p>
          </div>
          <div>
            <b>{t("authority:field.fax")}: </b>
            <p>{fax}</p>
          </div>
          <div>
            <b>{t("authority:field.application")}: </b>
            <p>
              {applicationForm
                ? t("authority:field.withForm")
                : t("authority:field.withoutForm")}
            </p>
          </div>
          <div>
            <b>{t("authority:field.sendInstallationProtocolToOffice")}: </b>
            <p>
              {sendInstallationProtocolToOffice
                ? t("general:yes")
                : t("general:no")}
            </p>
          </div>
        </div>
        <div>
          <b>{t("authority:field.hints")}: </b>
          <p>{hints}</p>
        </div>
        <div>
          <b>{t("authority:field.files")}: </b>
          <div className="grid grid-cols-2 gap-2">
            {attachments.map((attachment) => (
              <UploadedListItem key={attachment.id} file={attachment} />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
